import React, { FC, useEffect, useState } from 'react';
import styles from './AddOnRules.module.scss';
import { CompatibilityRule, CompatibilityRuleType, Family, ProductHeaders, Scope } from '../../data/model/DataModels';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { updateEditableProduct } from '../../data/slices/productSlice';
import AddOnRule from '../AddOnRule/AddOnRule';


export interface AddOnRulesProps {
  compatibilityRules: CompatibilityRule[];
  saveCompatibilityRules: Function;
  editMode?: boolean;
  defaultCompatibilityRuleType: CompatibilityRuleType;
  productApplicability: Scope[];
  productFamily: Family;
  productHeaders: ProductHeaders;
}

const AddOnRules: FC<AddOnRulesProps> = ({ compatibilityRules, saveCompatibilityRules, editMode = false, defaultCompatibilityRuleType, productApplicability, productFamily, productHeaders }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useAppDispatch();
  const editableProduct = useAppSelector((state) => state.product.editableProduct);
  const [localCompatibilitiyRules, setLocalCompatibilitiyRules] = useState(compatibilityRules.sort((a, b) => (b.ruleType.localeCompare(a.ruleType))));
  const [addOrEditARule, setAddOrEditARule] = useState(false);
  
  useEffect(() => {
    setLocalCompatibilitiyRules(compatibilityRules.sort((a, b) => (b.ruleType.localeCompare(a.ruleType))));
  }, [compatibilityRules]);

  const onCompatibilityRuleDefaultChange = (defaultRuleType: CompatibilityRuleType) => {
    editableProduct && dispatch(updateEditableProduct({ ...editableProduct, addOnRelationDefault: defaultRuleType }));
  }

  const updateCompatibilityRule = (compatibilityRuleToUpdate: CompatibilityRule) => {
    const updatedCompatibilityRules = compatibilityRules.map(compatibilityRule => {
      if(compatibilityRule.ruleType === compatibilityRuleToUpdate.ruleType) {
        return compatibilityRuleToUpdate;
      }
      else {
        return compatibilityRule;
      }
    })
    saveCompatibilityRules(updatedCompatibilityRules);
  }

  const getCompatibilityRuleTypeName = (ruleType: CompatibilityRuleType) => {
    if (ruleType === CompatibilityRuleType.MANDATORY) {
      return "Mandatory";
    }
    else if (ruleType === CompatibilityRuleType.OPT_IN) {
      return "Opt in";
    }
    else {
      return "Out out"
    }
  }

  const showRuleTitleTag = (compatibilityRule : CompatibilityRule) => {
    return <div key={compatibilityRule.ruleType} className={`${styles.ruleTitleTag} ${compatibilityRule.ruleType === CompatibilityRuleType.MANDATORY ? styles.mandatory : compatibilityRule.ruleType === CompatibilityRuleType.OPT_IN ? styles.optIn : styles.optOut}`}>
          {getCompatibilityRuleTypeName(compatibilityRule.ruleType)}: {defaultCompatibilityRuleType === compatibilityRule.ruleType ? "default" : compatibilityRule.rules.length!==0 ? compatibilityRule.rules.length + " rules" : "never"}
        </div>
  }

  return (
    <div className={styles.AddOnRules} data-testid="AddOnRules">
      <header>
        <div className={styles.headerTitle}>Add-on rules</div>
        { localCompatibilitiyRules.map(compatibilityRule => showRuleTitleTag(compatibilityRule)) }
        <div className={styles.allRulesFilter}><i className='pi pi-cog'/><span>All rules</span></div>
        <div className={`${styles.collapsibleArrow} ${isExpanded ? styles.rotateArrow : ""}`} onClick={() => setIsExpanded(!isExpanded)}><i className='pi pi-angle-down' /></div>
      </header>
      {
        isExpanded && localCompatibilitiyRules.map(compatibilityRule => {
          return <AddOnRule key={compatibilityRule.ruleType} compatibilityRule={compatibilityRule} editMode={editMode} setDefaultCompatibilityRuleType={(type: CompatibilityRuleType) => onCompatibilityRuleDefaultChange(type)} updateCompatibilityRule={(rule: CompatibilityRule) => updateCompatibilityRule(rule)} defaultCompatibilityRuleType={defaultCompatibilityRuleType}
          productApplicability={productApplicability} productHeaders={productHeaders} productFamily={productFamily} addOrEditARule={addOrEditARule} setAddOrEditARule={setAddOrEditARule} />
        })
      }
    </div>
  );
}

export default AddOnRules;


import { FC, createContext, memo, useContext, useEffect, useState, Dispatch, SetStateAction } from 'react';
import styles from './SolutionDetails.module.scss';
import { capitalize } from 'lodash';
import { TabView, TabPanel } from 'primereact/tabview';
import SpinnerComponent from '../../components/Spinner/SpinnerComponent';
import { ProductAndResources, useActivateDraftSolutionMutation, useApproveRejectSolutionMutation, useCancelSolutionApprovalMutation, useCreateSolutionApproverMutation, useDeleteSolutionDraftMutation, useGetAllUsersForProductQuery, useGetSolutionVersionsQuery, useLazyGetSolutionByCodeAndVersionQuery, usePostDraftSolutionMutation, usePostRoleAssignmentsMutation, useRetireSolutionMutation, useUpdateSolutionMutation } from '../../data/api/CatalogueApi';
import { ApprovalInfo, DimensionType, ROLES, ResourceApproversInfo, SelectedVersion, SolutionDefinition, SolutionDefnitionRequest, SolutionType, SolutionTypes, ResourceVersion, Status, ResourceVersions } from '../../data/model/DataModels';
import { FieldIdentifiers } from '../../utils/validationUtil';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import SolutionOverview from './SolutionOverview/SolutionOverview';
import ActivityComponent from '../../components/Activity/ActivityComponent.lazy';
import { Button } from 'primereact/button';
import { BreadCrumbContext } from '../../App';
import { addCrumb } from '../Breadcrumbs/Breadcrumbs';
import { ToasterContext } from '../AppLayoutView/AppLayoutView';
import CommercialInfo from '../../components/CommercialInfo/CommercialInfo';
import { confirmDialog } from 'primereact/confirmdialog';
import PATHS from '../../Paths';
import ApplicabilitiesViewIncremental from '../ApplicabilitiesViewIncremental/ApplicabilitiesViewIncremental.lazy';
import InputTextWithSave from '../../components/InputTextWithSave/InputTextWithSave';
import SolutionComponentAssociation from '../SolutionComponentAssociation/SolutionComponentAssociation';
import SolutionFeatureAssociations from '../SolutionFeatureAssociations/SolutionFeatureAssociations';
import { hasGlobalOwnerRole, hasRole, useAnchor } from '../../authorisationService';
import { getResourceNameFromProductCodeAndVersion } from '../../components/utils/userUtils';
import ApproversDialog from '../../components/ApproversDialog/ApproversDialog';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { uuidv4 } from '../../components/utils/utils';
import SolutionProductAssociation from '../SolutionProductAssociation/SolutionProductAssociation';
import { Dropdown } from 'primereact/dropdown';
import ErrorHandlerComponent from '../../components/ErrorHandler/ErrorHandlerComponent';
import { ReactComponent as RetireIcon } from "../../assets/icons/maersk/arrow-clockwise-times.svg"
import Ribbon from '../Ribbon/Ribbon.lazy';
import { EnvConfig } from '../../EnvConfig';
import SolutionProductAssociationNew from '../SolutionProductAssociationNew/SolutionProductAssociationNew';
import SolutionsComponentsAndFeaturesView from '../SolutionsComponentsAndFeaturesView/SolutionsComponentsAndFeaturesView';
import { ReactComponent as NonCompatibleBannerIcon } from '../../assets/icons/maersk/exclamation-octagon.svg';


export const solutionTabs = [
  { label: "Overview", hash: "" },
  { label: "Applicabilities", hash: "#applicabilities" },
  { label: "Products", hash: "#products" },
  { label: "Components & Features", hash: "#componentsfeatures" },
  { label: "Components", hash: "#components" },
  { label: "Features", hash: "#features" },
  { label: "Commercial Info", hash: "#salesinfo" },
  { label: "Activity", hash: "#activity" }
];

interface SolutionDetailsProps { }

interface SolutionContextInterface {
  solutionData?: SolutionDefinition;
  setSolutionData: Dispatch<SetStateAction<SolutionDefinition | undefined>>
}

export const SolutionContext = createContext<SolutionContextInterface>({ setSolutionData: () => { } });

export const constructBaseSolutionRequest = (solution: SolutionDefinition): SolutionDefnitionRequest => {
  return {
    id: solution.id,
    code: solution.code,
    version: solution.version,
    status: solution.status,
    lockingVersion: solution.lockingVersion,
  } as SolutionDefnitionRequest;
}

const SolutionDetails: FC<SolutionDetailsProps> = () => {

  const { code, version } = useParams();
  const { hash } = useLocation();
  const [showDraftModeInfo, setShowDraftModeInfo] = useState(true);
  const [showFeatureUpgradeInfo, setShowFeatureUpgradeInfo] = useState(true);
  const [showProductUpgradeInfo, setShowProductUpgradeInfo] = useState(true);
  const [featureInEditMode, setFeatureInEditMode] = useState(false);
  const isGlobalOwner = hasGlobalOwnerRole();

  const {
    data: solutionVersions,
    error: errorLoadingSolutionVersions,
    isLoading: isLoadingSolutionVersions,
  } = useGetSolutionVersionsQuery(code!,
    { skip: (!code && !version), refetchOnMountOrArgChange: true });
  let versionDropdownList: SelectedVersion[] = [];
  let selectedVersion;
  const breadcrumbs = useContext(BreadCrumbContext);
  const [updateSolution, { isLoading: isLoadingUpdateSolution }] = useUpdateSolutionMutation();
  const [activateSolutionDraft, { isLoading: isLoadingActivateSolution }] = useActivateDraftSolutionMutation();
  const [createDraftSolution, { isLoading: isLoadingCreateDraftSolution }] = usePostDraftSolutionMutation();
  const [deleteSolutionDraft, { isLoading: isLoadingDeleteDraft }] = useDeleteSolutionDraftMutation();
  const [saveSolutionApprover, { isLoading: isLoadingSaveSolutionApprover }] = useCreateSolutionApproverMutation();
  const [approveRejectSolution, { isLoading: isLoadingApprovalInfo }] = useApproveRejectSolutionMutation();
  const [cancelSolutionApproval, { isLoading: isLoadingCancelProductApproval }] = useCancelSolutionApprovalMutation();

  const navigate = useNavigate();

  const [solutionState, setSolutionState] = useState<SolutionDefinition>();
  const areRbacResourcesReady = (solutionState?.rbacResourceCode && solutionState?.rbacChildResourceCode && solutionState?.rbacResourceCode != "" && solutionState?.rbacChildResourceCode != '' && solutionState?.rbacResourceCode != "error" && solutionState?.rbacChildResourceCode != "error") || false;
  const [getSolutionByCodeAndVersion, { isFetching: isLoadingSolutionByCodeAndVersion }] = useLazyGetSolutionByCodeAndVersionQuery();
  const [editableFields, setEditableFields] = useState({ title: false });

  const isSolutionOwner = hasRole([solutionState?.code + "-" + solutionState?.version], ROLES.OWNER);
  const isSolutionContributor = hasRole([solutionState?.code + "-" + solutionState?.version], ROLES.CONTRIBUTOR);
  const isSolutionApprover = hasRole([solutionState?.code + "-" + solutionState?.version], ROLES.APPROVER);
  const isDraftSolution = solutionState?.status === Status.DRAFT;
  const isSolutionUnderReview = solutionState?.status === Status.REVIEW;
  const isActiveSolution = solutionState?.status === Status.ACTIVE;
  const isPackagedSolution = solutionState?.productType === SolutionType.PACKAGED_SOLUTION;
  const { data: allUsers, isLoading: isLoadingUsers, isError: isErrorUsers } = useGetAllUsersForProductQuery({ productCode: getResourceNameFromProductCodeAndVersion(solutionState?.code || '', solutionState?.version || 0), resourceCode: solutionState?.rbacResourceCode, resourceChildCode: solutionState?.rbacChildResourceCode } as ProductAndResources, { skip: !areRbacResourcesReady || isLoadingDeleteDraft });
  const [showSendForApprovalDialog, setShowSendForApprovalDialog] = useState(false);
  const [showHideRejectionDialog, setShowHideRejectionDialog] = useState(false);

  const [retireSolutionApi, { isLoading: isLoadingRetireSolution }] = useRetireSolutionMutation();


  const { pathname } = useLocation();
  const toaster = useContext(ToasterContext);

  const [triggerApproverStatus, setTriggerApproverStatus] = useState(0);
  const [rejectionComment, setRejectionComment] = useState("");
  const [showDraftRejectionInfo, setShowDraftRejectionInfo] = useState(true);
  let approversRejectedKeys: string[] = [];
  const { refreshAnchorToken } = useAnchor();

  if (solutionState?.approversRejected) {
    approversRejectedKeys = Object.keys(solutionState?.approversRejected || {});
  }

  if (isErrorUsers) {
    toaster.showToast("error", "Failed to load users");
  }

  if (errorLoadingSolutionVersions) {
    return (<ErrorHandlerComponent error={errorLoadingSolutionVersions} />);
  }

  useEffect(() => {
    loadSolution();
  }, [code, version]);

  const loadSolution = () => {
    if (code && version) {
      getSolutionByCodeAndVersion({ code: code, version: version, uuid: uuidv4() }, false).unwrap()
        .then(
          solution => {
            addCrumb({ label: solution?.name, url: pathname }, breadcrumbs);
            setSolutionState(solution);
          },
          () => {
            toaster.showToast('error', 'Failed to load solution');
          }
        )
    }

  }

  useEffect(() => {
    if (solutionState?.rbacResourceCode == "" || solutionState?.rbacChildResourceCode == "") {
      setTimeout(() => { fetchSolution(refreshAnchorToken) }, 5000);
    }
  }, [solutionState]);

  const isAnyProductWithNewVersionAvailable = (): boolean => {
    return solutionState?.products?.find(product => (product.latestActiveVersion > product.version!)) != undefined;
  }

  const isAnyProductWithEmptyRelationType = (): boolean => {
    return solutionState?.products?.find(product => ((product.relationType === "EMPTY") || (product.relationType === null))) != undefined;;
  }

  const getRelationTypeMissingProducts = (): string => {
    const products = solutionState?.products || [];
    const productCodes = products
      .filter(product => ((product.relationType === null) || (product.relationType === "EMPTY")))
      .map(product => product.name)
      .join(' , ');
    return productCodes;
  }

  const fetchSolution = (resolveCallback?: Function) => {
    if (code && version) {
      getSolutionByCodeAndVersion({ code: code, version: version, uuid: uuidv4() }).unwrap()
        .then(
          solution => {
            resolveCallback && resolveCallback();
            setSolutionState(solution);
          },
          () => {
            toaster.showToast('error', 'Failed to load solution');
          }
        )
    }
  }

  if (!solutionState) {
    return <SpinnerComponent />
  }

  const gotoTab = (tabIndex: number) => {
    navigate(solutionTabs[tabIndex].hash);
    setFeatureInEditMode(false);
  };

  const isSolutionInEditMode = (editableFields: any) => {
    const keys: string[] = Object.keys(editableFields);
    const obj = keys.find(key => editableFields[key] === true);
    if (obj)
      setFeatureInEditMode(true);
    else
      setFeatureInEditMode(false);
  }


  const resolveActiveTab = () => {
    const activeTab = solutionTabs.find(tab => tab.hash === hash.split("?")[0]);
    return activeTab ? activeTab : solutionTabs[0];
  };

  const anchorEmail = sessionStorage.getItem("anchorEmail") || "";

  const listOfActiveApprovers = allUsers ? allUsers.approvers?.map(approver => approver.email) : [];

  const sendForApproval = () => {
    const solutionApprovers: ResourceApproversInfo = {
      productCode: solutionState.code,
      version: solutionState.version,
      approvers: listOfActiveApprovers
    }
    saveSolutionApprover(solutionApprovers).unwrap()
      .then(response => {
        setShowSendForApprovalDialog(false);
        toaster.showToast('success', 'Approvers added successfully');
        fetchSolution();
      },
        () => {
          toaster.showToast('error', `Failed to add approvers`);
        })
  }

  const ShowTitle = memo(({ editMode, _name }: { editMode: boolean, _name: string }) => {
    const [name, setName] = useState(_name);

    const updateSolutionTitle = (input: string) => {
      setName(input.trimStart());
      setEditableFields({ ...editableFields, title: false });
      const solutionBaseRequest = solutionState && constructBaseSolutionRequest(solutionState);
      const solutionRequestData = { ...solutionBaseRequest, name: input.trimStart(), actionType: "updateName" } as SolutionDefnitionRequest;

      solutionState && updateSolution(solutionRequestData).unwrap().then(
        (response) => {
          setSolutionState({ ...solutionState, "name": response.name, "lockingVersion": response.lockingVersion });
          toaster.showToast('success', 'Solution name is successfully updated');
        },
        () => {
          toaster.showToast('error', 'Failed to update solution name');
        }
      )
    }

    return (
      <div className={styles.headerSection}>
        {editMode ? (
          <div className="title">
            <InputTextWithSave value={name} saveText={(input: string) => updateSolutionTitle(input)} cancel={() => setEditableFields({ ...editableFields, title: false })}
              fieldName={FieldIdentifiers.SOLUTION_TITLE} isRequired={true}
              validationMessage="Solution title is required" dataTestIds={['solNameInputWithSave', 'solNameInputText', 'solNameSaveBtn', 'solNameCancelBtn']} />
          </div>
        ) : (
          <>
            <p className={styles.solutionName}>{solutionState.name}</p>
            {isDraftSolution && isSolutionOwner && <i className={`pi pi-pencil ${styles.cursorPointer}`} onClick={() => { setEditableFields({ ...editableFields, title: true }) }} data-testid="solNameEditIcon" />}
          </>
        )}
      </div>
    );
  });


  const isFeatureVersionAvailable = (): boolean => {
    const filteredFeatureCodeVersions = solutionState.components?.flatMap(comp => comp.upgradedFeatureCode);
    if (filteredFeatureCodeVersions && filteredFeatureCodeVersions.length > 0) {
      const featureUpgradeAvailFlags = filteredFeatureCodeVersions.map(feat => {
        const matchedFeature = solutionState && solutionState.features.filter(feature => feat?.code === feature.featureRef);
        return (matchedFeature && matchedFeature.length > 0 && (feat?.featureVersion && feat?.featureVersion > matchedFeature[0].featureVersion)) ? true : false;
      });
      return featureUpgradeAvailFlags.filter(flag => flag).length > 0 ? true : false;
    }
    else
      return false;
  }


  const getSolutionTypeName = (solutionType: string) => {
    return SolutionTypes.find(solutionState => solutionState.code === solutionType)?.name;
  }

  const prepareMessage = (name: string, version: number) => {
    return version === 1 ? <span>You are about to activate Version {version} of {name}.<br />Are you sure you want to activate Version {version}?</span>
      : <span>You are about to activate Version {version} of {name}.<br /> which will deprecate the current version Version {version - 1}<br />Are you sure you want to activate Version {version}?</span>;
  }

  const confirmDraftActivation = (name: string, version: number) => {
    confirmDialog({
      message: prepareMessage(name, version),
      header: "Activate Draft Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Yes, Activate",
      rejectLabel: "Not Yet",
      rejectClassName: "dialog_reject",
      accept: async () => {
        activateDraft();
      },
      reject: () => {
        return;
      }
    });
  };

  const activateDraft = () => {
    solutionState && activateSolutionDraft(solutionState.code).unwrap().then(
      () => {
        fetchSolution();
        toaster.showToast('success', 'New solution version activated');
      },
      () => {
        toaster.showToast('error', 'Failed to activate solution draft');
      }
    )
  };

  const createOrEditDraft = () => {
    solutionState && createDraftSolution(solutionState.code).unwrap().then(
      (solutionState) => {
        refreshAnchorToken();
        navigate(PATHS.SOLUTION_BY_CODE_VERSION.replace(":code", solutionState.code).replace(":version", (solutionState.version).toString()))
      },
      () => {
        toaster.showToast('error', 'Failed to create draft');
      });
  };

  const confirmDraftDeletion = () => {
    confirmDialog({
      message: "You are about to delete the draft. This action cannot be undone. Are you sure you want to proceed?",
      header: "Delete Draft Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Delete",
      rejectLabel: "Cancel",
      accept: () => {
        if (solutionState && solutionState.code) {
          deleteSolutionDraft(solutionState.code).unwrap().then(() => {
            navigate("/solutions");
            toaster.showToast('success', 'Draft deleted successfully');
          },
            () => {
              toaster.showToast('error', 'Failed to delete draft');
            }
          );
        }
      },
      reject: () => {
        return;
      }
    });
  };

  const cancelApprovalAndEditSolution = () => {
    confirmDialog({
      message: "Are you sure you want to cancel the approval process and edit more?",
      header: "Cancel Approval",
      acceptLabel: "Yes, cancel approval",
      rejectLabel: "No",
      accept: () => {
        cancelSolutionApproval(code!).unwrap().then(
          (solutionDef) => {
            fetchSolution();
            toaster.showToast('success', 'Successfully cancelled the solution approval');
          },
          () => {
            toaster.showToast('error', 'Failed to cancel the solution approval');
          }
        );
      }
    });
  };

  const approveRejectAction = (approvalInfo: ApprovalInfo) => {
    approveRejectSolution(approvalInfo).unwrap().then(
      () => {
        approvalInfo.status === "approved" ?
          toaster.showToast('success', `You have approved the draft Version ${solutionState.version} of this solution`)
          : toaster.showToast('success', `You have rejected the draft Version ${solutionState.version} of this solution`);
        setShowHideRejectionDialog(false);
        fetchSolution();
      },
      () => {
        approvalInfo.status === "approved" ?
          toaster.showToast('error', `Failed to approve the draft Version ${solutionState.version} of this solution`)
          : toaster.showToast('error', `Failed to reject the draft Version ${solutionState.version} of this solution`);
        setShowHideRejectionDialog(false);
      }
    )
  }

  const checkIfApproverTookAction = () => {
    let approversWhoTookAction: string[] = approversRejectedKeys;
    if (solutionState.approversApproved) {
      approversWhoTookAction = [...approversWhoTookAction, ...solutionState.approversApproved];
    }
    return approversWhoTookAction.includes(anchorEmail);
  }

  const disableActivateMessage = () => {

    if (listOfActiveApprovers?.length !== solutionState.approversApproved?.length) {
      return `Awaiting approval from ${(listOfActiveApprovers?.length - (solutionState.approversApproved?.length || 0))} approvers`;
    }
    else if (featureInEditMode) {
      return "There are unsaved changes. Please, cancel or save the changes.";
    }
    else if (solutionState.productType == SolutionType.PACKAGED_SOLUTION && isAnyProductWithNewVersionAvailable()) {
      return "All the Products must be upgraded to their respective latest version"
    }
    return "";
  }

  const disabledSendForApprovalMessage = (): string => {
    if (featureInEditMode) {
      return "There are unsaved changes. Please, cancel or save the changes.";
    }
    else if (isPackagedSolution && solutionState.products.length < 2) {
      if (solutionState.products.length === 0)
        return "The solution does not contain any products";
      if (solutionState.products.length === 1)
        return "The solution must contain minimum two products";
      else
        return "";
    }
    else if (doesSolutionContainsRetiredProduct()) {
      return "The solution contains retired products";
    }
    else if (isAnyProductWithNewVersionAvailable()) {
      return "All the Products must be upgraded to their respective latest version"
    }
    else {
      return "";
    }
  }

  const onVersionSelect = (versionSelected: string) => {
    if (versionSelected === ResourceVersions.AllVersion && solutionState)
      navigate(PATHS.SOLUTION_BY_CODE_VERSION.replace(":code", code!).replace(":version", 'all'));
    else
      navigate(PATHS.SOLUTION_BY_CODE_VERSION.replace(":code", code!).replace(":version", versionSelected + ""));
  }


  const rejectDialogFooter = (
    <div>
      <Button label="Cancel" className="p-button-outlined p-button-secondary" onClick={() => setShowHideRejectionDialog(false)} />
      <Button label="Reject" className="p-button-danger" onClick={() => approveRejectAction({ status: "rejected", comment: rejectionComment, email: anchorEmail, productCode: solutionState.code } as ApprovalInfo)} />
    </div>
  );

  const isSendForApprovalDisabled = () => {
    if (!areRbacResourcesReady || featureInEditMode || !allUsers) {
      return true;
    }
    if (isPackagedSolution && solutionState.products.length < 2) {
      return true;
    }
    if (allUsers?.approvers == null || allUsers?.approvers?.length === 0) {
      return true;
    }
    const allPresentDimensionsInSolution = [...new Set(solutionState.applicability.map(scope => scope.dimensions).flat().map(dimension => dimension.type))];
    if ((allPresentDimensionsInSolution.includes(DimensionType.Import) || allPresentDimensionsInSolution.includes(DimensionType.Export)) && allPresentDimensionsInSolution.includes(DimensionType.Locations)) {
      return true;
    }
    if (doesSolutionContainsRetiredProduct()) {
      return true;
    }
    if (isAnyProductWithNewVersionAvailable()) {
      return true;
    }
    if (isAnyProductWithEmptyRelationType())
      return true;
  }

  const doesSolutionContainsRetiredProduct = () => {
    return solutionState?.products.some(prod => prod.status === Status.RETIRED);
  }

  if (solutionVersions && solutionVersions.resourceVersionDetailDtos?.length > 0) {
    let tempVersions: SelectedVersion[] = [];
    tempVersions = solutionVersions.resourceVersionDetailDtos.map(versionItem => ({
      ...versionItem,
      name: "Version " + versionItem.resourceVersion + " - " + capitalize(versionItem.status),
      code: versionItem.resourceVersion.toString()
    }));
    versionDropdownList = [...tempVersions, { name: "View all versions", code: ResourceVersions.AllVersion } as ResourceVersion];

    if (version === 'all')
      selectedVersion = versionDropdownList.filter(item => item.code === ResourceVersions.AllVersion)[0]?.code;
    else
      selectedVersion = versionDropdownList.filter(item => item.resourceVersion === Number(version))[0]?.code;
  }

  const showActionButtons = () => {
    if (isActiveSolution) {
      return (
        <>
          {
            EnvConfig.TOGGLES["ENABLE_RETIREMENT"] &&
            isGlobalOwner && <Button data-testid='editButton' icon={() => <RetireIcon style={{ width: "34px", margin: "-10px auto -10px -10px" }} />} label="Retire" className="p-button-outlined p-button-secondary" onClick={() =>
              retireSolution(solutionState.code)} disabled={!areRbacResourcesReady}
            />
          }
          {
            isSolutionOwner &&
            <Button data-testid='editButton' label="Create/View Draft" icon="pi pi-pencil" className="p-button-primary"
              onClick={() => createOrEditDraft()} />
          }
        </>
      )
    } else if (isDraftSolution || isSolutionUnderReview) {
      // if solution is under review or is in draft status
      return (
        <>
          {
            isSolutionOwner &&
            <>
              <Button label="Delete Draft" icon="pi pi-trash" className="p-button-outlined  p-button-secondary"
                onClick={() => confirmDraftDeletion()} disabled={!(solutionState.lockingVersion > 0)} />
              {
                isDraftSolution &&
                <div><Button label="Send For Approval" icon="pi pi-thumbs-up" className="p-button-primary" onClick={() => setShowSendForApprovalDialog(true)}
                  tooltip={disabledSendForApprovalMessage()}
                  disabled={isSendForApprovalDisabled()}
                  tooltipOptions={{ showOnDisabled: true, position: "left" }} /></div>
              }
              {
                isSolutionUnderReview &&
                <>
                  <Button label="Cancel Approval & Edit More" icon="pi pi-pencil" className="p-button-primary"
                    onClick={() => cancelApprovalAndEditSolution()} disabled={!areRbacResourcesReady} data-testid='editButton' />
                  <div>
                    <Button label="Activate" id="activateButton" icon="pi pi-check-circle" className={'p-button-secondary'}
                      onClick={() => confirmDraftActivation(solutionState.name, solutionState.version)} disabled={disableActivateMessage() != "" || featureInEditMode}
                      tooltip={disableActivateMessage()} tooltipOptions={{ appendTo: "self", disabled: disableActivateMessage() == "", showOnDisabled: true, position: "top" }}
                    />
                  </div>

                </>
              }
            </>
          }
          {
            isSolutionApprover && isSolutionUnderReview &&
            <>
              <Button label="Reject" icon="pi pi-times-circle" disabled={checkIfApproverTookAction()} className="p-button-outlined p-button-secondary" onClick={() => setShowHideRejectionDialog(true)} />
              <Button label="Approve" icon="pi pi-thumbs-up" disabled={checkIfApproverTookAction()} className="p-button-primary" onClick={() => approveRejectAction({ status: "approved", comment: "", email: anchorEmail, productCode: solutionState.code } as ApprovalInfo)} />
            </>
          }
        </>
      )
    }
  }

  const retireSolution = (solutionCode: string) => {
    let message = 'Once retired this solution will no longer be available for new opportunities.';
    let header = 'Retire solution?';

    confirmDialog({
      message: message,
      header: header,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Retire Solution",
      rejectLabel: "Cancel",
      accept: () => {
        retireSolutionApi(solutionCode).unwrap()
          .then(() => {
            toaster.showToast("success", "Solution is retired successfully");
            loadSolution();
          },
            (error: any) => {
              let errorMsg: string = error.data.message;
              errorMsg = errorMsg.includes("UM:") ? errorMsg.split("UM: ")[1] : 'Failed to retire solution';
              toaster.showToast('error', errorMsg);
            }
          )
      }
    });
  }

  return (
    <div className={styles.SolutionDetails} data-testid="solutionDetails">
      {
        (isLoadingSolutionByCodeAndVersion || isLoadingActivateSolution || isLoadingCreateDraftSolution || isLoadingDeleteDraft || isLoadingUpdateSolution || isLoadingSaveSolutionApprover || isLoadingApprovalInfo || isLoadingCancelProductApproval || isLoadingUsers || isLoadingSolutionVersions || isLoadingRetireSolution) &&
        <SpinnerComponent />
      }
      {
        solutionState &&
        <SolutionContext.Provider value={{ solutionData: solutionState, setSolutionData: setSolutionState }}>
          <div className={styles.solutionHeader}>
            <div className={styles.headerRow}>
              <div>
                <ShowTitle editMode={editableFields.title} _name={solutionState.name} />
                <div className={styles.productVersion}>
                  <span className="p-pr-2">Code: {solutionState.code},
                    <Dropdown className={styles.versionSelect}
                      value={selectedVersion}
                      options={versionDropdownList}
                      optionValue='code'
                      onChange={(e: any) => onVersionSelect(e.value)}
                      optionLabel="name"
                      data-testid="solutionVersionDropdown" />
                  </span>
                </div>
              </div>
              <div className={styles.actionButtons}>
                {showActionButtons()}
              </div>
            </div>
          </div>
          <div className={styles.ribbons}>
            {
              solutionState.status === "retired" &&
              <Ribbon status="error" iconClass='pi pi-exclamation-triangle'>You are viewing a retired version of this solution.</Ribbon>
            }
            {
              (isDraftSolution && showDraftModeInfo) &&
              <Ribbon status='info' iconClass={`pi pi-info-circle`} onClose={() => setShowDraftModeInfo(false)}>You are viewing a draft version of this {getSolutionTypeName(solutionState.productType)} solution.</Ribbon>
            }
            {
              (isFeatureVersionAvailable() && showFeatureUpgradeInfo) &&
              <Ribbon status='warn' iconClass={`pi pi-exclamation-circle`} onClose={() => setShowFeatureUpgradeInfo(false)}>New feature versions available.</Ribbon>
            }
            {
              (isAnyProductWithNewVersionAvailable() && showProductUpgradeInfo) &&
              <Ribbon status='warn' iconClass={`pi pi-exclamation-circle`} onClose={() => setShowProductUpgradeInfo(false)}>New product versions available. The solution must be upgraded.{solutionState.status === Status.DRAFT ? "" : " Please, create a new draft."}</Ribbon>
            }
            {
              (isAnyProductWithEmptyRelationType()) &&
              <span className={styles.relationTypeColor}>
                <span className={styles.ribbonInfoIcon}><NonCompatibleBannerIcon /></span>
                <span className={styles.relationType}>You must select product types for all products before you can submit for approval.
                  <br /><span>The following products are missing product type : </span> <span className={styles.missingProductNames}>{getRelationTypeMissingProducts()}</span></span>
              </span>
            }
          </div>

          {solutionState.status === "draft" && approversRejectedKeys?.length !== 0 && showDraftRejectionInfo &&
            <div className={`${styles.ribbonBar} ${styles.productDraftRejectionRibbon}`}>
              <div className={styles.ribbonInfo}>
                <div className={styles.ribbonInfoIcon}><i className="pi pi-exclamation-triangle"></i></div>
                <div>Your draft has been rejected by one of the approver, <span onClick={() => setTriggerApproverStatus(triggerApproverStatus + 1)} className={styles.link}>click here</span> to view the reason</div>
              </div>
              <div><i className={`pi pi-times ${styles.ribbonCloseIcon}`} onClick={() => setShowDraftRejectionInfo(false)}></i></div>
            </div>
          }
          {
            isSolutionOwner && showSendForApprovalDialog &&
            <ApproversDialog postAssignRoleMutation={usePostRoleAssignmentsMutation}
              header={"Send for Approval"} name={solutionState.name} rbacResourceCode={solutionState.rbacResourceCode} rbacResourceChildCode={solutionState.rbacChildResourceCode} productCode={solutionState.code} productVersion={solutionState.version} roleName={ROLES.APPROVER} showParentDialog={showSendForApprovalDialog} setShowParentDialog={setShowSendForApprovalDialog} sendForApproval={sendForApproval} />
          }
          <div className={`mainDisplayCard ${styles.mainCardPosition}`} data-testid="mainDisplayCard">
            <TabView activeIndex={solutionTabs.indexOf(resolveActiveTab())} onTabChange={(e) => gotoTab(e.index)} data-testid="tabView">
              <TabPanel header="Overview">
                <SolutionOverview
                  refetchSolution={() => fetchSolution()}
                  isEditable={isDraftSolution && isSolutionOwner} triggerApproverStatus={triggerApproverStatus} setTriggerApproverStatus={setTriggerApproverStatus} isSolutionInEditMode={isSolutionInEditMode} />
              </TabPanel>
              <TabPanel header="Applicabilities">
                <ApplicabilitiesViewIncremental isEditable={isDraftSolution && (isSolutionOwner || isSolutionContributor)} isSolutionInEditMode={isSolutionInEditMode} />
              </TabPanel>
              {solutionState.productType === SolutionType.PACKAGED_SOLUTION &&
                <TabPanel header="Products" headerClassName={isAnyProductWithNewVersionAvailable() ? styles.featureUpgradeBadge : ""}>
                  {
                    EnvConfig.TOGGLES["ENABLE_EXPANDABLE_VIEW"] ?
                      <SolutionProductAssociationNew refetchSolution={() => fetchSolution()} isEditable={isDraftSolution && (isSolutionOwner || isSolutionContributor)} setFeatureInEditMode={setFeatureInEditMode} featureInEditMode={featureInEditMode} isSolutionOwner={isSolutionOwner} />
                      :
                      <SolutionProductAssociation isEditable={isDraftSolution} isSolutionOwner={isSolutionOwner} />
                  }
                </TabPanel>
              }
              {EnvConfig.TOGGLES["ENABLE_EXPANDABLE_VIEW"] && solutionState.productType === SolutionType.INTEGRATED_SOLUTION &&
                <TabPanel header="Components & Features">
                  <SolutionsComponentsAndFeaturesView
                    refetchSolution={() => fetchSolution()}
                    isEditable={isDraftSolution && (isSolutionOwner || isSolutionContributor)}
                    setFeatureInEditMode={setFeatureInEditMode}
                    featureInEditMode={featureInEditMode}
                  />
                </TabPanel>
              }
              {!EnvConfig.TOGGLES["ENABLE_EXPANDABLE_VIEW"] && solutionState.productType === SolutionType.INTEGRATED_SOLUTION &&
                <TabPanel header="Components" headerClassName={isFeatureVersionAvailable() ? styles.featureUpgradeBadge : ""}>
                  <SolutionComponentAssociation isEditable={isDraftSolution && (isSolutionOwner || isSolutionContributor)} />
                </TabPanel>
              }
              {!EnvConfig.TOGGLES["ENABLE_EXPANDABLE_VIEW"] && solutionState.productType === SolutionType.INTEGRATED_SOLUTION &&
                <TabPanel header="Features" headerClassName={isFeatureVersionAvailable() ? styles.featureUpgradeBadge : ""}>
                  <SolutionFeatureAssociations
                    refetchSolution={() => fetchSolution()}
                    isEditable={isDraftSolution && (isSolutionOwner || isSolutionContributor)}
                    featureInEditMode={featureInEditMode}
                    setFeatureInEditMode={setFeatureInEditMode} />
                </TabPanel>
              }
              <TabPanel header="Commercial Info">
                <CommercialInfo attributes={solutionState.salesInfo} isEditable={isDraftSolution && isSolutionOwner} isSolutionInEditMode={isSolutionInEditMode} />
              </TabPanel>
              {
                isSolutionOwner &&
                <TabPanel header="Activity" data-testid="ActivityTab" >
                  <ActivityComponent type={"solutions"} code={solutionState.code} />
                </TabPanel>
              }
            </TabView>
            <Dialog visible={showHideRejectionDialog} header={"Reject Approval"} footer={rejectDialogFooter} onHide={() => setShowHideRejectionDialog(false)} style={{ width: '36vw' }}>
              <div className={styles.dialogBody} data-testid="rejectDraftProductDialog">
                <div className={styles.dialogLabel}>Brief reason for rejecting</div>
                <div><InputTextarea rows={8} cols={60} value={rejectionComment} autoResize onChange={(e) => setRejectionComment(e.target.value)} /></div>
              </div>
            </Dialog>
          </div>
        </SolutionContext.Provider>
      }
    </div >
  );
}

export default SolutionDetails;

import { AttributeCollection } from "./SharedDataInterfaces";
import { EnvConfig } from "../../EnvConfig";

interface BaseDefinition {
  id: number;
  code: string;
  lockingVersion: number;
  name: string;
}

export const enum ROLES { GLOBAL_OWNER = "owner", OWNER = "owner", CONTRIBUTOR = "contributor", REVIEWER = "reviewer", APPROVER = "approver" };
export enum USER_ACTION { EXPAND_ALL = "expand-all", COLLAPSE_ALL = "collapse-all", NONE = "none", VIEW_DETAILS = "view-details", PRODUCTS = "products", REORDER_COMPONENT = "reorder-component" };
export enum FAMILIES { LANDSIDE_TRANSPORT = "Landside Transport", LEAD_LOGISTICS = "Lead Logistics", OCEAN_CONTRACT = "Ocean Contract", OCEAN_FREESALE = "Ocean Freesale", OCEAN_VAS = "Ocean Value add", TRADELENS = "Tradelens", MAERSK_PROJECT_LOGISTICS = "Maersk Project Logistics", AIR = "Air", COLD_CHAIN_LOGISTICS = "Cold Chain Logistics", LCL = "LCL", CUSTOMS = "Customs", CARGO_RISK_MANAGEMENT = "Cargo Risk Management", COLD_STORAGE = "Cold Storage", DEPOT = "Depot", WAREHOUSING_FULFILMENT = "Warehousing & Fulfilment", middle_mile = "Middle Mile", LAST_MILE = "Last-Mile" }
export enum FAMILIES_CODES { DEPOT = "depot", LANDSIDE_TRANSPORT = "landside_transport", LEAD_LOGISTICS = "lead_logistics", OCEAN_CONTRACT = "ocean_contract", OCEAN_FREESALE = "ocean_freesale", OCEAN_VAS = "ocean_vas", TRADELENS = "tradelens", MAERSK_PROJECT_LOGISTICS = "maersk_project_logistics", AIR = "air", COLD_CHAIN_LOGISTICS = "cold_chain_logistics", LCL = "lcl", CUSTOMS = "customs", CARGO_RISK_MANAGEMENT = "cargo_risk_management", COLD_STORAGE = "cold_storage", WAREHOUSING_FULFILMENT = "warehousing_fulfilment", middle_mile = "middle_mile", LAST_MILE = "last_mile" }
export const FAMILIESMAPPING = {
  ecommerce_logistics: "Omnichannel Transportation",
  landside_transport: "Landside Transport",
  lead_logistics: "Lead Logistics",
  ocean_contract: "Ocean Contract",
  ocean_freesale: "Ocean Freesale",
  ocean_vas: "Ocean Value Add",
  ocean_special_cargo: "Ocean Special Cargo",
  tradelens: "Tradelens",
  maersk_project_logistics: "Maersk Project Logistics",
  air: "Air",
  lcl: "LCL",
  cold_chain_logistics: "Cold Chain Logistics",
  cold_storage: "Cold Storage",
  depot: "Depot",
  warehousing_fulfilment: "Warehousing & Fulfilment",
  middle_mile: "Middle Mile",
  customs: "Customs",
  cargo_risk_management: "Cargo Risk Management",
  last_mile: "Last-Mile"
}

export const enum Status {
  ACTIVE = "active",
  DRAFT = "draft",
  REVIEW = "review",
  OUTDATED = "outdated",
  RETIRED = "retired",
}

export enum CompatibilityRuleType { OPT_OUT = "OPT_OUT", OPT_IN = "OPT_IN", MANDATORY = "MANDATORY" }

export enum HeaderTypes {
  SERVICE_MODEL = "serviceModel",
  CARRIER = "carrier",
  INCOTERM = "incoterm",
  CONTRACT_VALIDITY = "contractValidity",
  // PACKAGING_TYPE = "packagingType",
  ATTRACTIVENESS = "attractiveness",
  CUSTOMER_TYPE = "customerType",
  VERTICAL = "vertical",
  VALUE_PROPOSITION = "valueProposition",
  LOAD_TYPE = "loadType",
  DIRECTION = "direction"
}

export enum ResourceVersions { AllVersion = "all-version" }
export enum PRODUCT_DEFAULT_HEADER_NAMES {
  SERVICE_MODEL = "serviceModel",
  ATTRACTIVENESS = "attractiveness",
  CUSTOMER_TYPE = "customerType",
  VERTICAL = "vertical"
}

export enum SOLUTION_DEFAULT_HEADER_NAMES {
  SERVICE_MODEL = "serviceModel"
}

export const HEADER_NAMES: Header[] = [
  {
    code: HeaderTypes.SERVICE_MODEL,
    name: 'Service Model'
  },
  {
    code: HeaderTypes.CUSTOMER_TYPE,
    name: 'Customer type'
  },
  {
    code: HeaderTypes.VERTICAL,
    name: 'Vertical'
  },
  {
    code: HeaderTypes.ATTRACTIVENESS,
    name: 'Attractiveness'
  },
  {
    code: HeaderTypes.CARRIER,
    name: 'Carrier'
  },
  {
    code: HeaderTypes.INCOTERM,
    name: 'Incoterms'
  },
  {
    code: HeaderTypes.CONTRACT_VALIDITY,
    name: 'Product Price Validity (Months)'
  },
  {
    code: HeaderTypes.LOAD_TYPE,
    name: 'Load Type'
  },
  {
    code: HeaderTypes.DIRECTION,
    name: 'Direction'
  }
  // {
  //     code: Headers.PACKAGING_TYPE,
  //     name: 'Packaging Type'
  // }
]

export const SOLUTION_HEADER_NAMES: Header[] = [
  {
    code: HeaderTypes.SERVICE_MODEL,
    name: 'Service Model'
  },
  {
    code: HeaderTypes.VERTICAL,
    name: 'Vertical'
  },
  {
    code: HeaderTypes.VALUE_PROPOSITION,
    name: 'Value Proposition'
  },
  {
    code: HeaderTypes.INCOTERM,
    name: 'Incoterm'
  },
  {
    code: HeaderTypes.CUSTOMER_TYPE,
    name: 'Customer Type'
  },
  {
    code: HeaderTypes.ATTRACTIVENESS,
    name: 'Attractiveness'
  }
]

export const SolutionsId = "solutions_id";

export const scopeTypes: string[] = ["inclusion", "exclusion"];

export enum Optionality {
  mandatory = "mandatory",
  defaulting = "defaulting",
  optional = "optional",
  restricted = "restricted",
}


export const SolutionTypes = [
  { name: "Packaged", code: "packaged_solution" },
  { name: "Integrated", code: "integrated_solution" }
];

export const ServiceDefaultLabel = {
  included: "Core Service",
  not_included: "Optional Service",
  none: "Non Service",
};


export const enum ProductType { PRODUCT_ADDON = "product-and-addon", PRODUCT = "product", ADDON = "addon" }

export const enum SolutionComponentOrigin { GENERAL = "general", COMPONENT_LIBRARY = "component_library", COMPONENT_FROM_PRODUCT = "component_from_product", NONE = "none" }

export const enum SolutionType { INTEGRATED_SOLUTION = "integrated_solution", PACKAGED_SOLUTION = "packaged_solution" }



export const approverDesignationEmailMap: { [key: string]: string } = {
  // 'n.sriram@maersk.com' : 'Head of LTA',
  'bich.nguyen@maersk.com': 'LTA SFPO'
};

export interface ProductInfo extends BaseDefinition {
  status: "active" | "retired" | "draft" | "review";
  version: number;
  family: Family;
  contracting: Optionality;
  header: Overview;
  headersInfo: ProductHeaders;
  salesInfo: SalesInfo;
  approvalFlow: boolean;
  isSolution?: boolean
  productType: ProductType;
}

export interface Family {
  code: string;
  name: string;
  portfolio: "Ocean" | "LnS";
  displayOrder: number;
  description: string;
}

export interface ComponentDefinition extends BaseDefinition {
  description: string;
  descriptionRichText: string;
  numberOfFeatures: number;
  numberOfSpecificationParameters: number;
  features: FeatureDefinition[];
  specificationParameters: SpecificationParameterDefinition[];
  associatedProducts: ProductInfo[];
  lastModifiedDatetime: Date;
  createdDatetime: Date;
}

export interface ComponentAssociation {
  componentRef: string;
  definition: ComponentDefinition;
  upgradedFeatureCode?: FeatureCodeAndVersion[];
}

export interface FeatureCodeAndVersion {
  code: string;
  featureVersion: number;
}

export interface FeatureAssociation {
  productId: string;
  featureRef: string;
  componentRef: string;
  definition: FeatureDefinition;
  featureVersion: number;
  service: boolean;
  serviceDefault: "included" | "not_included" | "none";
  isPriceNegotiable: boolean;
  chargeTypeCode: string;
}

export interface StatusInfo {
  name: string;
  code: string;
}

export enum SpecificationParameterType {
  none = "none",
  text = "text",
  selection = "selection",
  included = "included",
  range = "range",
  options = "options",
  group = "group",
  condition = "condition",
}
export const getRangeUnitOptions = () => [
  "kgs",
  "mton",
  "ft",
  "in",
  "m",
  "cm",
  "hours",
  "days",
  "weeks",
  "ffe",
];
export type RangeUnit =
  | ""
  | "kgs"
  | "mton"
  | "ft"
  | "in"
  | "m"
  | "cm"
  | "hours"
  | "days"
  | "weeks"
  | "ffe";

export const statusList: StatusInfo[] = [
  { name: "All versions", code: "all-versions" },
  { name: "Draft", code: Status.DRAFT },
  { name: "Active", code: Status.ACTIVE },
  { name: "Outdated", code: Status.OUTDATED },
  { name: "Retired", code: Status.RETIRED },
];

export interface PType {
  name: string;
  type: ProductType;
  icon?: string;
  description?: string;
}

export interface ComponentOrigin {
  name: string;
  type: SolutionComponentOrigin;
  icon?: string;
  description?: string;
  boldValue?: string;
}

export const serviceModelTypeDropDownList: HeaderOptions[] = [
  { name: 'Transported by Maersk', code: "transported_by_maersk" },
  { name: 'Fulfilled by Maersk', code: "fulfilled_by_maersk" },
  { name: 'Managed by Maersk', code: "managed_by_maersk" },
];

export const productTypeDropDownList: PType[] = [
  { name: 'Products and Add-ons', type: ProductType.PRODUCT_ADDON },
  { name: 'Products only', type: ProductType.PRODUCT },
  { name: 'Add-ons only', type: ProductType.ADDON },
];

export const statusDropDownList: CodeAndName[] = EnvConfig.TOGGLES["ENABLE_RETIREMENT"] ?
  [
    { name: 'Active', code: "active" },
    { name: 'Draft', code: "draft" },
    { name: 'Review', code: "review" },
    { name: 'Outdated', code: "outdated" },
    { name: 'Retired', code: "retired" },
  ]
  :
  [
    { name: 'Active', code: "active" },
    { name: 'Draft', code: "draft" },
    { name: 'Review', code: "review" },
    { name: 'Outdated', code: "outdated" },
  ];

export const productTypeFilterList: PType[] = [
  { name: 'Standalone product', type: ProductType.PRODUCT, icon: 'pi-sitemap', description: 'Standalone products can be sold independently' },
  { name: 'Add-on', type: ProductType.ADDON, icon: 'pi-th-large', description: 'Add-ons can only be sold along with standalone products' }
]

export const solutionViewComponentTypeFilterList: ComponentOrigin[] = [
  { name: '', type: SolutionComponentOrigin.COMPONENT_FROM_PRODUCT, icon: 'pi-sitemap', description: 'Add components from a', boldValue: "product" },
  { name: '', type: SolutionComponentOrigin.COMPONENT_LIBRARY, icon: 'pi-th-large', description: 'Add components from the', boldValue: "library" }
]


export interface RangeValue {
  min: number;
  max: number;
  unit: RangeUnit;
  percentage: boolean;
}

export interface OptionValue {
  code?: string;
  option: string;
  description: string;
  isDefault: boolean;
  isPriceNegotiable?: boolean;
}

export interface OptionalityNew {
  //todo rename when replace old one
  enabled: boolean;
  hasDefault: boolean;
  defaultValue: string; //todo this should be an enum
  required: boolean;
  canSelectNone: boolean;
}

export interface ConfigurableControl {
  enabled: boolean;
  allowNoneValue?: boolean;
  choiceRequired?: boolean;
  allowMultiselect?: boolean;
  defaultValue?: any;
}

export interface SpecificationParameterConfig {
  type: SpecificationParameterType;
  configurableAtContracting?: Optionality;
  selectableAtBooking?: Optionality;
  value: any;
  valueRepresentation?: string;
}

export interface SolutionDefnitionRequest {
  id: number;
  code: string;
  version: number;
  status: "active" | "retired" | "draft" | "review";
  lockingVersion: number;
  components?: ComponentAssociationRequest[];
  features?: FeatureAssociationRequest[];
  specificationParameters?: SpecificationParameterAssociationRequest[];
  header?: OverviewRequest;
  salesInfo?: SalesInfoRequest;
  applicability?: ScopeRequest[];
  contracting?: Optionality;
  isSolution?: boolean;
  actionType: string
}

export interface ComponentAssociationRequest {
  componentRef: string;
}

export interface FeatureAssociationRequest {
  featureRef: string;
  componentRef: string;
  featureVersion: number
}

export interface SpecificationParameterAssociationRequest {
  featureRef: string;
  featureVersion: number;
  componentRef: string;
  specificationParamRef: string;
  configuration?: SpecificationParameterConfig;
  configurableAtContracting?: ConfigurableControl;
  configurableAtBooking?: ConfigurableControl;
  note?: string;
  id?: number;
}

export interface OverviewRequest {
  description?: string;
  descriptionRichText?: string;
  keyContacts?: string;
  restriction?: string;
  limitation?: string;
  dependency?: string;
  customerCommitments?: string;
}

export interface SalesInfoRequest {
  tagline?: string;
  customerNeed?: string;
  bookingChannels?: string;
  compatibleProducts?: string;
  salesChannels?: string;
  targetCustomerSegments?: string;
  targetCustomerVerticals?: string;
  twowayCommitmentTagline?: string;
}

export interface ScopeRequest {
  index: number;
  code: string,
  codeUI?: string,
  dimensions?: Dimension[]
  scopeType?: ScopeType;
  name?: string,
  key?: string,
}

//todo update once new response structure is confirmed
export interface FeatureDefinition extends BaseDefinition {
  description: string;
  descriptionRichText: string;
  numberOfSpecificationParameters: number;
  componentRef: string;
  associationIndex: number;
  status?: string;
  version: number;
  specificationParameters: SpecificationParameterDefinition[];
  associatedProducts: ProductInfo[];
  rbacResourceCode: string;
  rbacChildResourceCode: string;
  approvalFlow?: boolean;
  approversApproved?: string[];
  approversRejected?: { [key: string]: string };
  service: boolean;
  serviceDefault: "included" | "not_included" | "none";
  associatedProductsForAllVersions: number;
  componentName: string;
}

export interface FeatureNameAndVersions {
  code: string;
  featureName: string;
  featureVersionDetailsDtos: FeatureVersion[];
}

export interface ResourceNameAndVersions {
  code: string;
  resourceName: string;
  resourceVersionDetailDtos: ResourceVersion[];
}

export interface SelectedVersion extends ResourceVersion {
  name: string;
  code: string;
}

export interface ResourceVersion extends BaseDefinition {
  resourceVersion: number;
  status: string;
  usedIn: number;
  createdDatetime: string;
  activationDatetime: string;
  retirementDatetime: string;
  lastModifiedDatetime: string;
}

export interface FeatureVersion extends BaseDefinition {
  featureVersion: number;
  status: string;
  usedIn: number;
  createdDatetime: string;
  activationDatetime: string;
  retirementDatetime: string;
  lastModifiedDatetime: string;
}

export interface SpecificationParameterAssociation {
  featureRef: string;
  componentRef: string;
  productId: number;
  specificationParamRef: string;
  definition: SpecificationParameterDefinition;
  configuration: SpecificationParameterConfig;
  configurableAtContracting?: ConfigurableControl;
  configurableAtBooking?: ConfigurableControl;
  note: string;
  id?: number;
  featureVersion: number;
  serviceDefault: "included" | "not_included" | "none";
  isPriceNegotiable: boolean;
}

export interface SpecificationParameterDefinition extends BaseDefinition {
  description: string;
  descriptionRichText: string;
  tags?: string[];
  componentRef: string;
  componentName: string;
  featureRef: string;
  configuration: SpecificationParameterConfig;
  configurableAtAssociation?: ConfigurableControl;
  configurableAtContracting?: ConfigurableControl;
  configurableAtBooking?: ConfigurableControl;
  associatedProducts: ProductInfo[];
  associatedSpecificationParameterByProduct: any;
  associationIndex: number;
  featureVersion: number;
  serviceDefault: "included" | "not_included" | "none";
  definition: SpecificationParameterDefinition;
  isService?: boolean;
  isPriceNegotiable?: boolean;
  featureServiceDefault?: string;
}

export interface NewFeatureDTO {
  description: string;
  descriptionRichText: string;
  tags: string[];
  componentRef: string;
  componentName: string;
  specification: SpecificationParameterConfig;
  configurableAtAssociation?: ConfigurableControl;
  configurableAtContracting?: ConfigurableControl;
  configurableAtBooking?: ConfigurableControl;
  associatedProducts: ProductInfo[] | null;
  associatedFeatureByProduct: {
    [key: string]: SpecificationParameterAssociation;
  };
  associationIndex: number;
  id: number;
  lockingVersion: number;
  code: string;
  name: string;
}

export enum DimensionType {
  Tradelane = "trade-lanes",
  ContainerSizeType = "container-types",
  Commodity = "commodities",
  CargoType = "cargo-types",
  Brands = "brands",
  Import = "imports",
  Export = "exports",
  ModeOfTransport = "transport-mode",
  Locations = "locations",
  OriginServiceMode = "origin-service-mode",
  DestinationServiceMode = "destination-service-mode"
}


export interface Dimension {
  // type should not be DimensionType as it would be dynamic form api
  type: string;
  value: any;
}


export interface Header {
  code: HeaderTypes;
  name: string;
}

export interface HeaderOptions {
  code: string;
  name: string;
}

export interface HeaderAttribute {
  type: HeaderTypes;
  name: string;
  value: Header[];
  icon?: JSX.Element
}


export type ScopeType = 'inclusion' | 'exclusion';

export interface Scope {
  index: number;
  code: string;
  codeUI?: string;
  dimensions: Dimension[];
  scopeType?: ScopeType;
  name: string;
  key: string;
}

export interface DimensionLoad<T extends DimensionItem> {
  data?: T[];
  isLoading: boolean;
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
}

export interface DimensionItem {
  code: string;
  name: string;
  type: string;
  children?: DimensionItem[];
}

export interface DimensionDefinition {
  code: string;
  name: string;
  description: string;
  examples: string[];
  status: boolean;
  disabled: boolean;
  isDefaultDimension: boolean;
  type: "list" | "tree" | "multitree";
}

export interface HeaderDefinition {
  code: string;
  name: string;
  description: string;
  examples: string[]
}

export const PRODUCT_HEADERS_DEFINITION_DATA: HeaderDefinition[] = [
  {
    code: HeaderTypes.SERVICE_MODEL,
    name: 'Service Model',
    description: 'Define the service models and set rules for them against other dimensions',
    examples: ["Transported by Maersk, Managed by Maersk, etc."]
  },
  {
    code: HeaderTypes.CUSTOMER_TYPE,
    name: 'Customer type',
    description: 'Define the customer types and set rules for them against other dimensions',
    examples: ["Beneficial Cargo Owner (BCO), etc."]
  },
  {
    code: HeaderTypes.VERTICAL,
    name: 'Vertical',
    description: 'Define the verticals and set rules for them against other dimensions',
    examples: ["Retail, Lifestyle, Technology, etc."]
  },
  {
    code: HeaderTypes.ATTRACTIVENESS,
    name: 'Attractiveness',
    description: 'Define the attractiveless and set rules for them against other dimensions',
    examples: ["Key Client, Booster, etc."]
  },
  {
    code: HeaderTypes.CARRIER,
    name: 'Carrier',
    description: 'Define the carriers and set rules for them against other dimensions',
    examples: ["MaerskLine, etc."]
  },
  {
    code: HeaderTypes.INCOTERM,
    name: 'Incoterm',
    description: 'Define the incoterms and set rules for them against other dimensions',
    examples: ["CIF, DDP, FOB, etc."]
  },
  {
    code: HeaderTypes.CONTRACT_VALIDITY,
    name: 'Product Price Validity (Months)',
    description: 'Define the price validity and set rules for them against other dimensions',
    examples: ["1-3 months, 12-24 months, etc."]
  },
  {
    code: HeaderTypes.LOAD_TYPE,
    name: 'Load Type',
    description: 'Define the load type and set rules for them against other dimensions',
    examples: ["Full Equipment, Less than full equipment, etc."]
  },
  {
    code: HeaderTypes.DIRECTION,
    name: 'Direction',
    description: 'Define the direction and set rules for them against other dimensions',
    examples: ["Destination (Import), Origin (Export), etc."]
  }
  // {
  //     code: Headers.PACKAGING_TYPE,
  //     name: 'Packaging Type'
  // }
]

export const SOLUTION_HEADERS_DEFINITION_DATA: HeaderDefinition[] = [
  {
    code: HeaderTypes.SERVICE_MODEL,
    name: 'Service Model',
    description: 'Define the service models and set rules for them against other dimensions',
    examples: ["Transported by Maersk, Managed by Maersk, etc."]
  },
  {
    code: HeaderTypes.VERTICAL,
    name: 'Vertical',
    description: 'Define the verticals and set rules for them against other dimensions',
    examples: ["Retail, Lifestyle, Technology, etc."]
  },
  {
    code: HeaderTypes.VALUE_PROPOSITION,
    name: 'Value Proposition',
    description: 'Define the value proposition and set rules for them against other dimensions',
    examples: ["AMBN, EASE, etc."]
  },
  {
    code: HeaderTypes.INCOTERM,
    name: 'Incoterm',
    description: 'Define the incoterms and set rules for them against other dimensions',
    examples: ["CIF, DDP, FOB, etc."]
  },
  {
    code: HeaderTypes.CUSTOMER_TYPE,
    name: 'Customer type',
    description: 'Define the customer types and set rules for them against other dimensions',
    examples: ["Beneficial Cargo Owner (BCO), etc."]
  },
  {
    code: HeaderTypes.ATTRACTIVENESS,
    name: 'Attractiveness',
    description: 'Define the attractiveless and set rules for them against other dimensions',
    examples: ["Key Client, Booster, etc."]
  }
]

export interface FamilyDimensionAssociation {
  dimensionCode: string;
  dimensionName: string;
  type: string;
  familyCode: string;
  disabled: boolean;
  isDefaultDimension: boolean;
}

export interface SelectedDimensions {
  dimensionCodes: { codes: string[] };
  familyCode: string;
}

export interface LocationData {
  type: "Region" | "Area" | "Country" | "City";
  code: string;
  name: string;
  parentCode: string;
  children: LocationData[];
}

export function emptyProductFrom(product: Product): Product {
  return {
    "id": product.id,
    "code": product.code,
    "lockingVersion": product.lockingVersion,
    "name": product.name,
    "version": product.version,
    "family": product.family,
    rbacResourceCode: "",
    rbacChildResourceCode: "",
    status: "draft",
    contracting: Optionality.defaulting,
    category: "standalone",
    components: [],
    specificationParameters: [],
    approvalFlow: false,
    features: [],
    applicability: [],
    approversApproved: [],
    approversRejected: {},
    variances: [],
    headersInfo: {
      serviceModel: [] as Header[],
      carrier: [] as Header[],
      incoterm: [] as Header[],
      attractiveness: [] as Header[],
      customerType: [] as Header[],
      contractValidity: [] as Header[],
      vertical: [] as Header[],
      valueProposition: [] as Header[],
      loadType: [] as Header[],
      direction: [] as Header[]
    },
    compatibility: {
      compatibilityRules: [],
      compatibleItems: []
    },
    header: {
      description: "",
      descriptionRichText: "",
      keyContacts: "",
      restriction: "",
      limitation: "",
      dependency: "",
      customerCommitments: "",
    }, salesInfo: {
      tagline: "",
      customerNeed: ""
    },
    associatedSolutions: [],
    productType: ProductType.PRODUCT,
    isSolution: false,
    addOnRelationDefault: CompatibilityRuleType.OPT_OUT
  }
}


export interface FamilyCodeAndDescription {
  name: string;
  code: string;
  description: Function;
}

export const getContractingText = (contracting: Optionality): string => {
  switch (contracting) {
    case Optionality.mandatory:
      return "contract";
    case Optionality.restricted:
      return "freesale";
    case Optionality.optional:
    case Optionality.defaulting:
      return "contract and freesale";
  }
};

export interface CompatibilityRule {
  ruleType: CompatibilityRuleType;
  rules: Rule[];
}

export interface Rule {
  name: string;
  code?: string;
  codeUI?: string;
  criteria: Criterion[];
  createdDate?: Date;
  lastModifiedDate?: Date;
  criteriaValidationResults?: CriteriaValidation[]
  validationMsg?: string;
}

export interface CriteriaValidation {
  dimensionType: string;
  missingValues: string[];
  validationMessage?: string;
}

export interface Criterion {
  dimension: DimensionType | HeaderTypes,
  values: string[]
}

export interface Compatibility {
  compatibilityRules: CompatibilityRule[];
  compatibleItems: ProductListing[];
}

export interface Product extends ResourceDefinition {
  family: Family;
  category: "standalone" | "dependent" | "addon";
  variances: Scope[];
  productType: ProductType
  headersInfo: ProductHeaders;
  associatedSolutions: UsedInSolution[];
  templateAvailability?: boolean;
  compatibility: Compatibility;
  addOnRelationDefault: CompatibilityRuleType;
}

export interface UsedInSolution {
  code: string;
  name: string;
  status: string;
  version: number;
}


export interface ResourceDefinition extends BaseDefinition {
  version: number;
  rbacResourceCode: string;
  rbacChildResourceCode: string;
  components: ComponentAssociation[];
  specificationParameters: SpecificationParameterAssociation[];
  features: FeatureAssociation[];
  header: Overview;
  salesInfo: SalesInfo;
  applicability: Scope[];
  approvalFlow: boolean;
  status: "active" | "retired" | "draft" | "review";
  contracting: Optionality;
  approversApproved?: string[];
  approversRejected?: { [key: string]: string }
  isSolution: boolean;
}

export interface OverviewIncrementalEdit {
  name: boolean;
  description: boolean;
  keyContacts: boolean;
  limitation: boolean;
  dependency: boolean;
  customerCommitments: boolean;
  serviceModel: boolean;
  vertical: boolean;
  valueProposition: boolean;
}

export interface ApplicabilityIncrementalEdit {
  name: boolean;
  [DimensionType.Brands]: boolean,
  [DimensionType.CargoType]: boolean,
  [DimensionType.ContainerSizeType]: boolean,
  [DimensionType.Commodity]: boolean,
  [DimensionType.Export]: boolean,
  [DimensionType.Import]: boolean,
  [DimensionType.Tradelane]: boolean,
  [DimensionType.Locations]: boolean
  [DimensionType.OriginServiceMode]: boolean
  [DimensionType.DestinationServiceMode]: boolean
}

export interface CommercialInfoIncrementalEdit {
  tagline: boolean;
  twowayCommitmentTagline: boolean;
  customerNeed: boolean;
  targetCustomerSegments: boolean;
  targetCustomerVerticals: boolean;
  salesChannels: boolean;
  bookingChannels: boolean;
  compatibleProducts: boolean;
}

export interface SolutionDefinition extends ResourceDefinition, SolutionCreation {

}

export interface SolutionCreation {
  name: string;
  headersInfo: ProductHeaders;
  productType: SolutionType;
  header: Overview;
  products: ProductListing[];
  salesInfo: SalesInfo;
}

export interface ResourceDefinition extends BaseDefinition {
  version: number;
  rbacResourceCode: string;
  rbacChildResourceCode: string;
  components: ComponentAssociation[];
  specificationParameters: SpecificationParameterAssociation[];
  features: FeatureAssociation[];
  header: Overview;
  salesInfo: SalesInfo;
  applicability: Scope[];
  approvalFlow: boolean;
  status: "active" | "retired" | "draft" | "review";
  contracting: Optionality;
  approversApproved?: string[];
  approversRejected?: { [key: string]: string }
  isSolution: boolean;
}

export interface CodeAndName {
  name: string;
  code: string;
}


export interface ProductListing {
  code: string;
  name: string;
  description?: string,
  family?: Family,
  status?: string;
  version: number;
  productType?: string;
  // features?: FeatureDefinition[];
  features: FeatureAssociation[];
  // components: CodeAndName[];
  components: ComponentAssociation[];
  rbacResourceCode?: string;
  latestActiveVersion: number;
  specificationParameters: SpecificationParameterAssociation[];
  products?: ProductListing[];
  isCompatible?: boolean;
  relationType: string;
}

export type ProductHeaders = {
  [key in HeaderTypes]?: Header[];
};

export interface ProductSlice {
  selectedComponent: ComponentAssociation;
  features: FeatureDefinition[];
  editMode: boolean;
  createViewDraft: boolean;
  isReordering: boolean;
  editableProduct: Product;
  editableProductCopy: Product;
  localDraftChanged: boolean;
  showVarianceDetails: boolean;
  selectedVariance: any;
  isProductFormValid: boolean;
  family: string;
}

export interface Overview extends AttributeCollection {
  description: string;
  descriptionRichText: string;
  keyContacts: string;
  restriction: string;
  limitation: string;
  dependency: string;
  customerCommitments: string;
}

export interface SalesInfo extends AttributeCollection {
  tagline: string;
  customerNeed: string;
}

export interface Activity {
  user: {
    name: string;
    email: string;
  };
  activity: {
    type: string;
    subtype: string;
    date: string;
    time: string;
  };
  entity: {
    type: string;
    code: string;
    version: string;
  };
  subject?: {
    name: string;
    email: string;
  };
}

export interface CargoType extends DimensionItem {
  code: string;
  name: string;
}

export interface CommodityGroup extends DimensionItem {
  code: string;
  name: string;
}

export interface Brand extends DimensionItem {
  code: string;
  name: string;
}

export interface SortFeature {
  featureRef: string;
  featureVersion: number;
}

export interface SortFeaturesRequest {
  componentRef: string;
  featureOrderList: SortFeature[];
}

export interface SortSpecificationParameterRequest {
  featureRef: string;
  featureVersion: number;
  specificationParameterOrderList: string[];
}

//OIPC GOVERNANCE
export interface UserDefinition {
  name: string;
  email: string;
  roles: ROLES[];
}

export interface AnchorSubscriptionDefinition {
  roles: string;
  subId: string;
  subscription_name: string;
}

export interface AnchorResource {
  resource_id: "string";
  resource_name: "string";
  resource_description: "string";
  subscription_id: "string";
  subscription_name: "string";
  subscription_description: "string";
  is_active: true;
  created_at: "string";
  created_by: "string";
  updated_at: "string";
  updated_by: "string";
  roles_name: "string";
  permission_name: "string";
  permission_id: "string";
  parent_id: "string";
  children: AnchorResource[];
  parent: AnchorResource[];
  permission: any[];
}

//This User is structured on Graph api user response
export interface User {
  businessPhones?: string[],
  displayName: string,
  givenName?: string,
  jobTitle?: string,
  mail: string,
  mobilePhone?: string,
  officeLocation?: string,
  preferredLanguage?: string,
  surname?: string,
  userPrincipalName?: string,
  id: string,
  me?: boolean
}

export interface AnchorUserAssignment {
  id: string;
  active: boolean;
  role_id: string;
  role_name: string;
  user_id: string;
  user_idp: string;
  group_id: string;
  group_email: string;
  group_name: string;
  name: string;
  email: string;
  type: string;
  scope: string;
  scope_type?: string;
  created_by: string;
  created_by_name: string;
  created_at: string;
  updated_by: string;
  updated_at: string;
  updated_by_name: string;
  deleted_by: string;
  familyCode: string;
}

export interface AllUserData {
  owners: AnchorUserAssignment[];
  contributors: AnchorUserAssignment[];
  reviewers: AnchorUserAssignment[];
  approvers: AnchorUserAssignment[];
  [key: string]: AnchorUserAssignment[]; //index signature
}

export interface AllRoleData {
  owner: AnchorRoleInfo;
  contributor: AnchorRoleInfo;
  reviewer: AnchorRoleInfo;
  approver: AnchorRoleInfo;
}

export interface AnchorRoleInfo {
  roles_description: string;
  roles_id: string;
  roles_name: string;
  permission_name: string[]
}

export interface AnchorFamily {
  id: string;
  name: string;
  roleName: string;
  data: AnchorUserAssignment[] | undefined;
  error: any;
  isLoading: boolean;
}
export interface AssignUsersRequest {
  productCode: string;
  resourceCode: string;
  resourceChildCode: string;
  shouldInheritRoleAssignment: boolean;
  roles: RoleAssign[];
  version?: number;
}

export interface AssignUsersResponse {
  type: string;
  resource_id: string;
  subscription_id: string;
  inherit: boolean;
  roles: RoleAssign[];
  successResponses?: UserAsignmentResponseMessages[];
  errorMessages?: UserAsignmentResponseMessages[];
}

export interface UserAsignmentResponseMessages {
  role_id: string;
  resource_id: string;
  subscription_id: string;
  user_id: string;
  user_idp: string;
  user_email: string;
  type: string;
  messages: string[];
}

export interface RoleAssign {
  role_id: string;
  role_name: string;
  users: UserAssignWithUserName[];
}

export interface UserAssignWithUserName {
  user_name: string;
  user_email: string;
  user_idp: string;
}

export interface ApprovalInfo {
  status: "approved" | "rejected";
  email: string;
  productCode: string;
  productId: string;
  comment: string;
  lockingVersion?: number;
  version?: number;
}

export interface RoleObject {
  rolesId: string;
  rolesName: string;
  rolesDescription: string;
  isSystemRole: string;
  createdAt: string;
}

export interface ProfileObject {
  userId: string;
  user_name: string;
  user_email: string;
  isActive: true;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  deletedAt: string;
  deletedBy: string;
}

export interface ApprovalInfo {
  roles: RoleObject[];
  profile: ProfileObject;
}

export interface ResourceApproversInfo {
  productCode: string,
  approvers: string[],
  version?: number
}

export interface ApproverStatus {
  id: number,
  email: string,
  productCode: string,
  status: string,
  comment: string,
  lockingVersion?: number
}

export interface SolutionProductUpgradeDTO {
  solutionId: number,
  productCode: string,
  productVersion: number
}

export const PackagedSolutionProductRelationType = [
  { name: "Core Mandatory", code: "CORE", description: "The product is always included in opportunity and cannot be removed manually. The Solution cannot be offered without this product in case of applicability conflict" },
  { name: "Optional Opt-Out", code: "OPTIONAL_OPT_OUT", description: "The product is included in opportunity by default but can be manually removed. The Solution can still be offered without this product in case of applicability conflict" },
  { name: "Optional Opt-In", code: "OPTIONAL_OPT_IN", description: "The product is not included in opportunity by default but needs manual addition. The Solution can still be offered without this product in case of applicability conflict" }
];

export interface SolutionProductRelationTypeChangeDTO {
  solutionId: number,
  productCode: string,
  relationType: string
}
import { FC, useContext, useMemo, useState } from 'react';
import styles from './SolutionProductAssociationNew.module.scss';
import { SolutionContext, constructBaseSolutionRequest } from '../SolutionDetails/SolutionDetails';
import { USER_ACTION, SolutionType, SolutionDefnitionRequest, ProductListing, SolutionProductUpgradeDTO, FeatureAssociation, SpecificationParameterDefinition, SolutionProductRelationTypeChangeDTO } from '../../data/model/DataModels';
import ProductAccordion from '../../components/ProductAccordion/ProductAccordion';
import TruncatedText from '../../utils/TruncatedText';
import ProductCardView from '../../components/ProductCardView/ProductCardView';
import AssociationDetailsComponent from '../../components/AssociationDetailsComponent/AssociationDetailsComponent';
import { Button } from 'primereact/button';
import { useUpdateSolutionMutation, useUpdateSolutionProductMutation, useUpgradeSolutionProductMutation } from '../../data/api/CatalogueApi';
import { ToasterContext } from '../AppLayoutView/AppLayoutView';
import ProductSelectionDialog from '../../components/ProductSelectionDialog/ProductSelectionDialog';
import SpinnerComponent from '../../components/Spinner/SpinnerComponent';
import { Dialog } from 'primereact/dialog';
import UpgradeSolutionProductView from '../SolutionDetails/UpgradeSolutionProductView/UpgradeSolutionProductView';
import { cloneDeep } from 'lodash';
import { confirmDialog } from 'primereact/confirmdialog';
import FeatureDetailPanel from '../../components/FeatureDetailPanel/FeatureDetailPanel';

export interface SolutionProductAssociationNewProps {
  refetchSolution: Function
  isEditable?: boolean
  isSolutionOwner?: boolean
  setFeatureInEditMode: Function
  featureInEditMode: boolean
}

const SolutionProductAssociationNew: FC<SolutionProductAssociationNewProps> = ({ refetchSolution, isEditable = false, isSolutionOwner = false, setFeatureInEditMode, featureInEditMode }) => {
  const solutionState = useContext(SolutionContext);
  const solution = solutionState.solutionData;
  const [showProductSelectionDialog, setShowProductSelectionDialog] = useState(false);
  const [updateSolution, { isLoading: isLoadingUpdateSolution }] = useUpdateSolutionMutation();
  const toaster = useContext(ToasterContext);
  const [userAction, setUserAction] = useState(USER_ACTION.COLLAPSE_ALL);
  const [upgradeSolutionProduct, { isLoading: isLoadingUpgradeSolutionProduct }] = useUpgradeSolutionProductMutation();
  const [showUpgradeProductDialogue, setShowUpgradeProductDialogue] = useState(false);
  const [productToBeUpgraded, setProductToBeUpgraded] = useState({ "code": "", "version": 0 } as { "code": string, "version": number });
  const [openFeatureDetailSidePanelFor, setOpenFeatureDetailSidePanelFor] = useState<FeatureAssociation>();
  const [selectedSpecParamCode, setSelectedSpecParamCode] = useState('' as string);
  const [selectedParentProductCode, setSelectedParentProductCode] = useState('' as string);
  const [updateSolutionProduct, { isLoading: isLoadingUpdateSolutionProduct }] = useUpdateSolutionProductMutation();

  if (!solution) {
    return <></>
  }

  const sharedComponentsData = useMemo(() => {
    const componentOccurrences = new Map<string, string[]>();
    const componentCodeAndNameMap = new Map<string, string>();
    solution.products?.forEach((product) => {
      product.components?.forEach((component) => {
        const componentCode = component.componentRef;
        if (componentOccurrences.has(componentCode)) {
          componentOccurrences.get(componentCode)?.push(product.name);
        } else {
          componentOccurrences.set(componentCode, [product.name]);
        }
        componentCodeAndNameMap.set(componentCode, component.definition.name);
      });
    });
    const sharedComponentMap = new Map<string, string[]>();
    componentOccurrences.forEach((products, componentCode) => {
      if (products && products.length > 1) {
        sharedComponentMap.set(componentCode, products);
      }
    });
    return { "componentCodeAndProductListMap": sharedComponentMap, "componentCodeAndNameMap": componentCodeAndNameMap };
  }, [solution.products])

  const sharedComponent = () => {
    return (
      <>
        <h2 data-testid="sharedComponents">Shared Components</h2>
        {sharedComponentsData.componentCodeAndProductListMap && sharedComponentsData.componentCodeAndProductListMap.size > 0 ? (<div >
          <div className={styles.sharedComponent}>
            {Array.from(sharedComponentsData.componentCodeAndProductListMap).map(([componentCode, products], index) => (
              <div key={index} className={styles.blockComponent}>
                <div>
                  {
                    <TruncatedText text={sharedComponentsData.componentCodeAndNameMap.get(componentCode)} maxLength={50} />
                  }
                </div>
                <div className={styles.productList}>
                  <span>in</span><span className={styles.products}>
                    {
                      <TruncatedText text={products.join(", ")} maxLength={100} />}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>) : <div className={styles.noSharedComponent}> - No shared components</div>
        }
      </>
    );
  }

  const addProductsToSolution = (productsToBeAdded: ProductListing[]) => {
    const solutionBaseRequest = constructBaseSolutionRequest(solution);
    const solutionRequestData = { ...solutionBaseRequest, products: [...solution?.products, ...productsToBeAdded], actionType: "addProduct" } as SolutionDefnitionRequest;
    updateSolution(solutionRequestData).unwrap().then(
      (response) => {
        const responseToBeUpdated = {
          ...solution,
          lockingVersion: response.lockingVersion,
          products: response?.products
        };
        solutionState.setSolutionData(responseToBeUpdated);
        toaster.showToast('success', 'Products are added successfully');
      },
      () => {
        toaster.showToast('error', 'Failed to add products');
      }
    )
    setShowProductSelectionDialog(false);
  }

  const updateProductInSolution = (relationType: string, productCode: string) => {
    const solutionProductUpgradeRequestData: SolutionProductRelationTypeChangeDTO = { solutionId: solution.id, productCode: productCode, relationType: relationType };
    updateSolutionProduct(solutionProductUpgradeRequestData).unwrap().then(
      (response: ProductListing) => {
        solutionState.setSolutionData(
          {
            ...solution,
            products: solution.products.map(product => (product.code === response.code) ? { ...response, relationType: relationType } : product)
          });
        toaster.showToast('success', 'Product is updated successfully');
      },
      () => {
        toaster.showToast('error', 'Failed to update the product');
      }
    )
  }

  const removeProductFromSolution = (e: any, productToBeRemoved: ProductListing) => {
    e.preventDefault();
    e.stopPropagation();
    const updatedProducts = solution?.products.filter(product => product.code != productToBeRemoved.code);
    const solutionBaseRequest = constructBaseSolutionRequest(solution);
    const solutionRequestData = {
      ...solutionBaseRequest, products: cloneDeep(updatedProducts).map(product => {
        product.rbacResourceCode = "";
        return product;
      })
    } as SolutionDefnitionRequest;
    updateSolution(solutionRequestData).unwrap().then(
      (response) => {
        solutionState.setSolutionData(
          {
            ...solution,
            lockingVersion: response.lockingVersion,
            products: response?.products
          });
        toaster.showToast('success', 'Product is removed successfully');
      },
      () => {
        toaster.showToast('error', 'Failed to remove product');
      }
    )
  }

  const closeDialogueAndNavigateToProductTab = () => {
    setShowUpgradeProductDialogue(false)
    setProductToBeUpgraded({ "code": '', "version": 0 });
  }

  const upgradeProductVersion = () => {
    const solutionProductUpgradeRequestData: SolutionProductUpgradeDTO = { solutionId: solution.id, productCode: productToBeUpgraded.code, productVersion: productToBeUpgraded.version };
    upgradeSolutionProduct(solutionProductUpgradeRequestData).unwrap().then(
      (response) => {
        solutionState.setSolutionData(
          {
            ...solution,
            products: solution.products.map(product => (product?.code === response?.code) ? response : product)
          });
        toaster.showToast('success', 'Product is upgraded successfully');
      },
      () => {
        toaster.showToast('error', 'Failed to upgrade the product');
      }
    )
    setShowUpgradeProductDialogue(false)
  }

  const upgradeOrPreviewFooter = (
    <div>
      {(isEditable) ?
        (
          <>
            <Button
              label={"Cancel"}
              className={styles.previewBtn}
              onClick={() => closeDialogueAndNavigateToProductTab()}
            />
            <Button
              label="Upgrade"
              className={styles.upgradeOrCloseBtn}
              onClick={() => {
                upgradeProductVersion();
                setProductToBeUpgraded({ "code": '', "version": 0 });
              }}
            />
          </>)
        :
        (<Button
          label={"Close"}
          className={styles.upgradeOrCloseBtn}
          onClick={() => closeDialogueAndNavigateToProductTab()}
        />)
      }
    </div>
  );

  const confirmRemoveProduct = (e: any, product: ProductListing) => {
    e.preventDefault();
    e.stopPropagation();
    confirmDialog({
      message: `Are you sure you want to remove product ${product.name} from this solution?`,
      header: 'Remove product?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => removeProductFromSolution(e, product),
    });
  }

  const previewUpgradeProduct = (e: any, product: ProductListing) => {
    e.preventDefault();
    e.stopPropagation();
    setShowUpgradeProductDialogue(true);
    setProductToBeUpgraded({ "code": product.code, "version": product.latestActiveVersion || 0 });
  }

  const getSpecificationParameterDefinitions = (featureAssociation: FeatureAssociation) => {
    const parentAssociatedProduct = solution.products.find(product => product.code === selectedParentProductCode);
    return parentAssociatedProduct ?
      parentAssociatedProduct?.specificationParameters.filter(specParam => specParam.featureRef === featureAssociation.featureRef)
        .map(spAssociation => {
          return {
            ...spAssociation,
            code: spAssociation.specificationParamRef,
          } as unknown as SpecificationParameterDefinition
        })
      :
      [];
  }

  const onFeatureTitleClick = (featureCode: string, productCode: string) => {
    setSelectedParentProductCode(productCode);
    setOpenFeatureDetailSidePanelFor(solution.products.find(product => product.code === productCode)?.features.find(feature => feature.featureRef === featureCode));
  }

  const onSpecParamTitleClick = (featureCode: string, specParamCode: string, productCode: string) => {
    setSelectedParentProductCode(productCode);
    setOpenFeatureDetailSidePanelFor(solution.products.find(product => product.code === productCode)?.features.find(feature => feature.featureRef === featureCode));
    setSelectedSpecParamCode(specParamCode);
  }

  const closeSidePanel = () => {
    setOpenFeatureDetailSidePanelFor(undefined);
    setSelectedParentProductCode('');
    setSelectedSpecParamCode('');
  }

  const showSection = (action: USER_ACTION) => {
    switch (action) {
      case USER_ACTION.EXPAND_ALL:
      case USER_ACTION.COLLAPSE_ALL:
      case USER_ACTION.NONE:
        return <>
          {solution.productType === SolutionType.PACKAGED_SOLUTION &&
            <ProductAccordion productList={solution.products} expandAll={userAction === USER_ACTION.EXPAND_ALL ? true : false} isEditable={isEditable}
              removeProduct={(e: any, product: ProductListing) => confirmRemoveProduct(e, product)}
              previewUpgradeProduct={(e: any, product: ProductListing) => previewUpgradeProduct(e, product)}
              onFeatureTitleClick={onFeatureTitleClick}
              onSpecParamTitleClick={onSpecParamTitleClick}
              updateProduct={(relationType: string, productCode: string) => updateProductInSolution(relationType, productCode)}
            />
          }
        </>
      case USER_ACTION.VIEW_DETAILS:
        return <AssociationDetailsComponent refetchSolution={refetchSolution} isEditable={isEditable} setFeatureInEditMode={setFeatureInEditMode} featureInEditMode={featureInEditMode}
          removeProduct={(e: any, product: ProductListing) => confirmRemoveProduct(e, product)}
          previewUpgradeProduct={(e: any, product: ProductListing) => previewUpgradeProduct(e, product)}
          updateProduct={(relationType: string, productCode: string) => updateProductInSolution(relationType, productCode)} />
      case USER_ACTION.PRODUCTS:
        return <ProductCardView isEditable={isEditable} isSolutionOwner={isSolutionOwner}
          removeProduct={(e: any, product: ProductListing) => confirmRemoveProduct(e, product)}
          previewUpgradeProduct={(e: any, product: ProductListing) => previewUpgradeProduct(e, product)} updateProduct={(relationType: string, productCode: string) => updateProductInSolution(relationType, productCode)} />
      default:
        <></>
    }
  }


  return (
    <div className={styles.SolutionProductAssociationNew} data-testid="SolutionProductAssociationNew">
      {(isLoadingUpdateSolution || isLoadingUpgradeSolutionProduct || isLoadingUpdateSolutionProduct) && <SpinnerComponent />}
      {
        solution.productType === SolutionType.PACKAGED_SOLUTION && sharedComponent()
      }
      <div className={styles.productsHeader}>
        <div className={styles.productCountDropdown}>
          {solution.productType === SolutionType.PACKAGED_SOLUTION ?
            <h2 data-testid="totalproduct">Products ({solution?.products.length})</h2> :
            <h2 data-testid="totalcomponent">Components ({solution?.components.length})</h2>
          }
        </div>
        {isEditable && <div className={styles.buttonSection}>
          <Button label="Add Product" icon="pi pi-plus" className="p-button-outlined p-button-secondary" onClick={() => setShowProductSelectionDialog(true)} />
        </div>
        }
      </div>
      <div className={styles.actionSection}>
        <div onClick={() => {setUserAction(USER_ACTION.EXPAND_ALL)}} className={styles.actionIconLabel}><span className={`${(userAction === USER_ACTION.EXPAND_ALL || userAction === USER_ACTION.NONE) ? styles.underline : ''}`}>Expand all</span><i className="pi pi-angle-down" data-testid="expand-all-link" /></div>
        <div onClick={() => setUserAction(USER_ACTION.COLLAPSE_ALL)} className={styles.actionIconLabel}><span className={`${userAction === USER_ACTION.COLLAPSE_ALL ? styles.underline : ''}`}>Collapse all</span><i className="pi pi-angle-up" data-testid="collapse-all-link" /></div>
        {/* 
        
        Disabled this sections based on the discussion happened on the epic/oipc-766
     
        <div onClick={() => setUserAction(USER_ACTION.VIEW_DETAILS)} className={styles.actionIconLabel}><i className="pi pi-eye" data-testid="view-details-link" /><span className={`${userAction === USER_ACTION.VIEW_DETAILS ? styles.underline : ''}`}>Details View</span></div>
        {solution.productType === SolutionType.PACKAGED_SOLUTION && <div onClick={() => setUserAction(USER_ACTION.PRODUCTS)} className={styles.actionIconLabel}><i className="pi pi-th-large" data-testid="products-link" /><span className={`${userAction === USER_ACTION.PRODUCTS ? styles.underline : ''}`}>Products View</span></div>} 
        
        */}
      </div>
      {showSection(userAction)}
      {
        openFeatureDetailSidePanelFor !== undefined &&
        <FeatureDetailPanel isEditable={false} onHide={() => closeSidePanel()} feature={openFeatureDetailSidePanelFor} specificationParameters={getSpecificationParameterDefinitions(openFeatureDetailSidePanelFor)} selectedSpecParamCode={selectedSpecParamCode} />
      }
      {
        showProductSelectionDialog &&
        <ProductSelectionDialog onHide={() => setShowProductSelectionDialog(false)} saveProductSelection={addProductsToSolution} existingProducts={solution?.products}></ProductSelectionDialog>
      }
      {
        showUpgradeProductDialogue && <Dialog
          header="Upgrade new product version?"
          footer={upgradeOrPreviewFooter}
          visible={showUpgradeProductDialogue}
          onHide={() => closeDialogueAndNavigateToProductTab()}
          style={{ minHeight: "90%", width: "90%", maxHeight: "90%" }}
          data-testid="upgradeDialogue"
        >
          <UpgradeSolutionProductView
            subHeader={isEditable ? "You're about to upgrade this solution with the latest version of the product:" : "Latest version of the product:"}
            code={productToBeUpgraded.code}
            version={productToBeUpgraded.version}
          />
        </Dialog>
      }
    </div>
  )
};

export default SolutionProductAssociationNew;

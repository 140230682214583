import React, { useContext, useEffect, useMemo, useState } from 'react';
import styles from './ComponentAccordion.module.scss';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { ComponentAssociation, FeatureAssociation, SpecificationParameterAssociation, ProductListing, SolutionType } from '../../data/model/DataModels';
import FeatureAccordion from '../FeatureAccordion/FeatureAccordion';
import { ReactComponent as ComponentIcon } from '../../assets/icons/maersk/component-icon.svg';
import './overrides.scss'
import { confirmPopup } from 'primereact/confirmpopup';
import DisplayFeatureCards from '../../features/ProductView/ProductFeatures/DisplayFeatureCards/DisplayFeatureCards';
import { Tooltip } from 'primereact/tooltip';
import { SolutionContext } from '../../features/SolutionDetails/SolutionDetails';

export interface ComponentAccordionProps {
  productAssociation?: ProductListing;
  componentList: ComponentAssociation[];
  featureList: FeatureAssociation[];
  specificationParameterList: SpecificationParameterAssociation[];
  expandAll: boolean;
  index: number;
  updateParent?: Function;
  deleteComponent?: Function;
  deleteFeature?: Function;
  deleteSpecificationParameter?: Function;
  isEditable: boolean;
  addFeatures?: Function;
  isNewFeatureVersionAvailable?: Function;
  setSelectedFeatureForUpgrade?: Function;
  onFeatureTitleClick?: Function;
  onSpecParamTitleClick?: Function;
}

const ComponentAccordion: React.FC<ComponentAccordionProps> = ({
  componentList,
  featureList,
  specificationParameterList,
  index,
  isEditable,
  deleteComponent,
  deleteFeature,
  deleteSpecificationParameter,
  expandAll,
  addFeatures,
  isNewFeatureVersionAvailable,
  setSelectedFeatureForUpgrade,
  onFeatureTitleClick,
  onSpecParamTitleClick
}) => {
  const [activeIndex, setActiveIndex] = useState(index === 0 ? [index] : []);
  const [showAddFeaturesModalFor, setShowAddFeaturesModalFor] = useState("");
  const [selectedFeatuteForEdit, setSelectedFeatuteForEdit] = useState('' as string);

  useEffect(() => {
    if (expandAll === true) {
      setActiveIndex(componentList.map((feature, index) => index));
    }
    else {
      setActiveIndex([]);
    }
  }, [expandAll]);


  const getFeaturesForComponent = (componentAssociation: ComponentAssociation) => {
    return featureList?.filter(({ componentRef }) => componentAssociation.componentRef === componentRef);
  }

  const confirmComponentDelete = (event: any, componentRef: string) => {
    event.preventDefault();
    event.stopPropagation();
    confirmPopup({
      target: event.currentTarget,
      message: 'Are you sure you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => deleteComponent && deleteComponent(componentRef),
      reject: () => null
    });
  }

  const checkAllFeatureAssociation = (componentAssociation: ComponentAssociation) => {
    const sourceComponent = componentList.find(comp => comp.componentRef === componentAssociation.componentRef);
    return sourceComponent ? !(sourceComponent?.definition.numberOfFeatures > getFeaturesForComponent(componentAssociation).length) : true;
  }

  const getAssociatedFeatureCodes = useMemo(() => {
    const assocFeatures = featureList.filter(feature => feature.componentRef == showAddFeaturesModalFor);
    return assocFeatures.map(feat => feat.featureRef);
  }, [showAddFeaturesModalFor]);

  const showAddFeatureModal = (event: any, componentAssociation: ComponentAssociation) => {
    event.preventDefault();
    event.stopPropagation();
    !checkAllFeatureAssociation(componentAssociation) && setShowAddFeaturesModalFor(componentAssociation.componentRef);
  }

  const processAndAddFeatures = (features: FeatureAssociation[]) => {
    setShowAddFeaturesModalFor("");
    addFeatures && addFeatures(features);
  }

  return (
    <div className={`${styles.componentAccordion} componentAccordion`} data-testid="ComponentAccordion">
      <Accordion multiple activeIndex={activeIndex} onTabChange={(e: any) => setActiveIndex(e.index)}>
        {componentList.map((componentAssociation, index) => {
          return (
            <AccordionTab key={index} header={
              <div className={styles.componentView}>
                <span className={styles.descriptionAlignment}>
                  <span className={styles.componentIconName}>
                    <ComponentIcon />
                    <span className={styles.componentName}>{componentAssociation.definition.name}</span>
                  </span>
                  <span className={styles.componentDescription}>{componentAssociation.definition.name}</span>
                </span>
                {isEditable &&
                  <span className={styles.componentActionButtons}>
                    <div onClick={(e) => showAddFeatureModal(e, componentAssociation)} data-testid={`addfeature-${index}`}
                      className={`tooltipAddFeature-${index} ${styles.componentAddFeature} ${checkAllFeatureAssociation(componentAssociation) || selectedFeatuteForEdit !== '' ? styles.disabled : styles.enabled}`}>
                      <i className='pi pi-plus' />
                      <span>Add Feature</span>
                    </div>
                    <div>
                      <Tooltip target={`.tooltipAddFeature-${index}`} className="general-tooltip" autoHide={true} position="top" disabled={!checkAllFeatureAssociation(componentAssociation)}>
                        All features are already associated with the component
                      </Tooltip>
                    </div>
                    <i className={`pi pi-trash ${styles.enabled}`} onClick={(e) => confirmComponentDelete(e, componentAssociation.componentRef)} data-testid={`deleteComponent-${index}`}></i>
                  </span>
                }
              </div>
            }>
              <FeatureAccordion featureList={getFeaturesForComponent(componentAssociation)} specificationParameterList={specificationParameterList} index={index} isEditable={isEditable}
                selectedFeatureForEdit={selectedFeatuteForEdit} expandAll={expandAll}
                removeFeature={(featureRef: string) => deleteFeature && deleteFeature(featureRef)}
                removeSpecificationParameter={(specParamCode: string) => { deleteSpecificationParameter && deleteSpecificationParameter(specParamCode) }}
                isNewFeatureVersionAvailable={isNewFeatureVersionAvailable}
                setSelectedFeatureForUpgrade={setSelectedFeatureForUpgrade}
                onFeatureTitleClick={onFeatureTitleClick}
                onSpecParamTitleClick={onSpecParamTitleClick}
              />
            </AccordionTab>
          )
        })}
      </Accordion>
      {
        showAddFeaturesModalFor !== "" &&
        <DisplayFeatureCards header={"Component name"} onHide={() => setShowAddFeaturesModalFor("")} activeFeatures={getAssociatedFeatureCodes} componentRef={showAddFeaturesModalFor} acceptAction={(features: FeatureAssociation[]) => processAndAddFeatures(features)} />
      }
    </div>
  )
};

export default ComponentAccordion;

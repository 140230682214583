import React, { FC, useState, useEffect } from 'react';
import styles from './RuleComponent.module.scss';
import './overrides.scss';
import { CompatibilityRuleType, Criterion, DimensionType, HeaderTypes, Rule } from '../../data/model/DataModels';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import CriterionComponent from '../CriterionComponent/CriterionComponent';
import { FieldIdentifiers, formValidation } from "../../utils/validationUtil";
import RuleComponentReadView from '../RuleComponentReadView/RuleComponentReadView';
import { confirmPopup } from 'primereact/confirmpopup';


export interface RuleComponentProps {
  rule: Rule;
  compatibilityRuleType: CompatibilityRuleType;
  availableDimensions: { name: string, code: DimensionType }[];
  availableHeaders: { name: string, code: HeaderTypes }[];
  saveRule: Function;
  deleteRule: Function;
  editMode: boolean;
  addOrEditARule: boolean;
  setAddOrEditARule: Function;
}

const RuleComponent: FC<RuleComponentProps> = ({ rule, compatibilityRuleType, availableDimensions, availableHeaders, saveRule, deleteRule, editMode, addOrEditARule, setAddOrEditARule }) => {
  const [editRule, setEditRule] = useState(!rule?.code ? true : false);
  const [isEditBtnClicked, setIsEditBtnClicked] = useState(false);
  const allAvailableDimensions: { name: string, code: DimensionType | HeaderTypes }[] = [...availableDimensions, ...availableHeaders];
  const [localRule, setLocalRule] = useState(rule);

  useEffect(() => {
    setLocalRule(rule);
  }, [rule])
  

  const unusedDimensions = (): { name: string, code: DimensionType | HeaderTypes }[] => {
    return allAvailableDimensions.filter(dimensionItem => 
        !localRule.criteria.some(criterion => criterion.dimension === dimensionItem.code)
    );
  }

  let invalidFields: string[] = [];
  const [isFormValid, setIsFormValid] = useState(true);

  useEffect(() => {
    setIsFormValid(invalidFields.length == 0 && checkRequiredFieldAvailability());
  })

  const addCriterion = () => {
    const filteredList = unusedDimensions();
    const newCriterion : Criterion = {
      dimension : filteredList[0].code,
      values : []
    }
    setLocalRule({...localRule, criteria : [...localRule.criteria, newCriterion]});
  }

  const deleteCriterion = (dimension: DimensionType | HeaderTypes) => {
    setLocalRule({...localRule, criteria : localRule.criteria.filter(criterion => criterion.dimension !== dimension)});
  }

  const getTodaysDate = () : string => {
    const currentDate: Date = new Date();
    const currentYear: number = currentDate.getFullYear();
    const currentMonth: number = currentDate.getMonth() + 1;
    const currentDay: number = currentDate.getDate();
    const todaysDateInYYYYMMDD = `${currentYear}-${currentMonth < 10 ? '0' + currentMonth : currentMonth}-${currentDay}`;
    return todaysDateInYYYYMMDD;
  }

  const saveRuleBtnAction = () => {
    saveRule({...localRule, lastModifiedDate : getTodaysDate()});
    setEditRule(false);
    setIsEditBtnClicked(false);
    setAddOrEditARule(false);
  }

  const cancelBtnAction = () => {
    if(!localRule?.code && !isEditBtnClicked)
      deleteRule(localRule);
    setLocalRule(rule);
    setEditRule(false);
    setAddOrEditARule(false);
  }

  const checkRequiredFieldAvailability = () => {
    const criterionWithEmptyValue = localRule.criteria.filter(criterion => criterion.values.length === 0)
    return localRule.name.length > 0 && criterionWithEmptyValue.length === 0 ? true : false
  };

  const checkFormValidity = (value: string, fieldIdentifier: FieldIdentifiers, fieldName: string, isRequired: boolean, validationMessage: string) => {
    const validityResult = formValidation(value, fieldIdentifier, isRequired, validationMessage);
    if (validityResult !== "") {
      if (!invalidFields.includes(fieldName)) {
        invalidFields = [...invalidFields, fieldName];
      }
    } else {
      if (invalidFields.length > 0) {
        invalidFields = invalidFields.filter(field => field !== fieldName);
      }

    }
    return validityResult;
  }

  const confirmRuleDelete = (event: any, rule: Rule) => {
    event.preventDefault();
    event.stopPropagation();
    confirmPopup({
      target: event.currentTarget,
      message: 'Are you sure you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => deleteRule(rule),
      reject: () => null
    });
  }

  const showRule = () => {
    if(editRule) {
      return editView();
    }
    else {
      return <>
        <RuleComponentReadView rule={localRule} allAvailableDimensions={allAvailableDimensions}/>
        { editMode && 
          <div className={styles.editAndDeleteAction}>
            <div><Button label="Edit" icon="pi pi-pencil" onClick={() => {setEditRule(true); setIsEditBtnClicked(true); setAddOrEditARule(true)}} disabled={addOrEditARule} severity="secondary" outlined size="small" /></div>
            <div><Button label="Delete" icon="pi pi-trash" onClick={(e) => confirmRuleDelete(e, rule)} disabled={addOrEditARule} severity="secondary" outlined size="small" /></div>
         </div>
        }
      </>
    }
  }

  const editView = () => {
    return (
      <div className={styles.RuleComponent} data-testid="RuleComponent">
        <div>
          Rule name<label style={{ color: "red" }}>*</label>
        </div>
        <div>
          <span className={styles.valueField}>
            <InputText className={checkFormValidity(localRule.name, FieldIdentifiers.RULE_NAME, "ruleName", true, "") === "" ? "" : "p-invalid"} value={localRule.name} onChange={(e) => setLocalRule({...localRule, name : e.target.value})} key={compatibilityRuleType} />
            {checkFormValidity(localRule.name, FieldIdentifiers.RULE_NAME, "ruleName", true, "")}
          </span>      
        </div>
        <div className={styles.ruleCriteria}>
          {Array.from(localRule.criteria)
            .map((criteriaDimension, index) => {
              return <CriterionComponent criteriaDimensionKey={criteriaDimension.dimension} criteriaDimensionValue={criteriaDimension.values} allAvailableDimensions={allAvailableDimensions} 
                      index={index} rule={localRule} setRule={(rule: Rule) => setLocalRule(rule)} deleteCriterion={(dimensionKey: DimensionType | HeaderTypes) => deleteCriterion(dimensionKey)} key={criteriaDimension.dimension+rule.codeUI} 
                      criterionValuesValidationFn={(criterionValues : string[]) => checkFormValidity(criterionValues[0], FieldIdentifiers.CRITERION_VALUES, "criterionValues", true, "Please select at least one value")}/>
            })
          }
          <div className={styles.addCriteriaBtn}>
            <Button label='Add criteria' onClick={() => addCriterion()} disabled={unusedDimensions().length === 0} className='p-button-outlined p-button-secondary' icon='pi pi-plus' />
          </div>
        </div>
        {
          
          <div className={styles.ruleActionBtn}>
            <Button label="Save" icon="pi pi-save" onClick={() => saveRuleBtnAction()} severity="secondary" disabled={!isFormValid}/>
            <Button label="Cancel" icon="pi pi-times" onClick={() => cancelBtnAction()} severity="secondary" outlined />
          </div>
        }
      </div>
    );
  }

  return showRule();
  
}

export default RuleComponent;

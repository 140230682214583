import { CodeAndName, Dimension, DimensionDefinition, DimensionType, LocationData, ScopeType } from '../../data/model/DataModels';
import { dimensionIncludeValues } from "../utils/dimensionData";
import { ReactComponent as Container } from '../../assets/icons/maersk/container-stacked.svg';
import { ReactComponent as Brands } from '../../assets/icons/maersk/vessel-front.svg'
import { ReactComponent as Cargo } from '../../assets/icons/maersk/container-reefer.svg';
import { ReactComponent as Commodities } from '../../assets/icons/custom/commodities.svg';
import { ReactComponent as ExportLocations } from '../../assets/icons/maersk/container-up.svg';
import { ReactComponent as ImportLocations } from '../../assets/icons/maersk/container-down.svg';
import { ReactComponent as Pin } from '../../assets/icons/maersk/pin.svg';
import { ReactComponent as Tradelanes } from '../../assets/icons/maersk/route.svg';
import { ReactComponent as ModeOfTransport } from '../../assets/icons/maersk/truck-side.svg';
import { ReactComponent as OriginServiceMode } from '../../assets/icons/maersk/container-hook.svg';
import { ReactComponent as DestinationServiceMode } from '../../assets/icons/maersk/mi-crane-24px.svg';




export interface Option {
	label: string,
	subLabel: string,
	value: string,
	scopeType: string
}

export const buildDimensionsWithAllValues = (dimensionCodes: string[], allDimensionsData: DimensionDefinition[], regions: LocationData[]): Dimension[] => {
	const dimensionsToMap = allDimensionsData.filter(dimension => dimensionCodes.includes(dimension.code));

	return dimensionsToMap.map(dimension => {
		if (dimension.type === "multitree") {
			return {
				type: dimension.code,
				value: regions?.map(region => { return { code: region.code, values: [dimensionIncludeValues.ALL_INCLUDED] } })
			} as Dimension;
		}
		return { type: dimension.code, value: [dimensionIncludeValues.ALL_INCLUDED] } as Dimension;

	});
}

export const getDimensionIcon = (type: string) => {
	switch (type) {
		case DimensionType.Brands:
			return <Brands />
		case DimensionType.Import:
			return <ImportLocations />
		case DimensionType.Export:
			return <ExportLocations />
		case DimensionType.Locations:
			return <Pin />
		case DimensionType.CargoType:
			return <Cargo />
		case DimensionType.ContainerSizeType:
			return <Container />
		case DimensionType.Commodity:
			return <Commodities />
		case DimensionType.Tradelane:
			return <div style={{ padding: "0.4rem" }}><Tradelanes /></div>
		case DimensionType.ModeOfTransport:
			return <ModeOfTransport />
		case DimensionType.OriginServiceMode:
			return <OriginServiceMode />
		case DimensionType.DestinationServiceMode:
			return <DestinationServiceMode />
		default:
			<></>
	}
}

export const addKeysToTree: any = (node: any, parentKey: string = "") => {
	const formKey = (code: string, parentCode: string = "") => {
		return parentCode != "" ? parentCode + "-" + code : code
	}

	const key = formKey(node?.code, parentKey);
	if (node?.children?.length > 0) {
		return { ...node, key, label: node.name, children: node.children.map((child: any) => addKeysToTree(child, key)) }
	} else {
		return { ...node, label: node.regionName !== undefined && node.regionName !== "" && node.regionName !== null ? `${node.cityName} (${node.regionName}), ${node.countryName}` : node.name, key }
	}
}

export const addKeysToTreeAndRemoveCities: any = (node: any, parentKey: string = "") => {
	const formKey = (code: string, parentCode: string = "") => {
		return parentCode != "" ? parentCode + "-" + code : code
	}

	if(node.type==="Country"){
		node.children = [];
	}

	const key = formKey(node?.code, parentKey);
	if (node?.children?.length > 0) {
		return { ...node, key, label: node.name, children: node.children.map((child: any) => addKeysToTreeAndRemoveCities(child, key)) }
	} else {
		return { ...node, label: node.regionName !== undefined && node.regionName !== "" && node.regionName !== null ? `${node.cityName} (${node.regionName}), ${node.countryName}` : node.name, key }
	}
}

// This function segregates tree from the main tree based on selected values.
// For inclusion it will create tree from the selected values.
// For Exclusion it will create tree other than the selected values.

export const asyncBuildTreeFromSelectedValuesAndAddIcons = async (regions: LocationData[], selected: string[] = [], type: ScopeType = "inclusion") => {
	return buildTreeFromSelectedValuesAndAddIcons(regions, selected, type);
}

export const buildTreeFromSelectedValuesAndAddIcons = (regions: LocationData[], selected: string[] = [], type: ScopeType = "inclusion") => {
	const selectionIcon = type == "inclusion" ? "pi pi-check-circle" : "pi pi-ban";

	const processEntityBasedOnChildren = (entity: any, filteredResult: any[]) => {
		if (filteredResult.length > 0) {
			if (filteredResult.length < entity.children.length) {
				return { ...entity, icon: "pi pi-circle", children: filteredResult };
			} else {
				if (filteredResult.map((element: any) => element.icon).includes("pi pi-circle")) {
					return { ...entity, icon: "pi pi-circle", children: filteredResult };
				} else {
					return { ...entity, icon: selectionIcon, children: filteredResult };
				}
			}
		}
	}

	const filterBasedOnSelectionForInclusion = (entities: any[]) => {
		let mappedEntities: any[] = [];
		entities.forEach(entity => {
			if (entity.children?.length > 0) {
				const filteredResult: any = filterBasedOnSelectionForInclusion(entity.children);
				const processedEntity = processEntityBasedOnChildren(entity, filteredResult);
				if (processedEntity) {
					mappedEntities.push(processedEntity);
				}
			} else {
				if (selected.includes(entity.code)) {
					mappedEntities.push({ ...entity, icon: selectionIcon });
				}
			}
		})
		return mappedEntities;
	}

	const filterBasedOnSelectionForExclusion = (entities: any[]) => {
		let mappedEntities: any[] = [];
		entities.forEach(entity => {
			if (entity.children?.length > 0) {
				const filteredResult: any = filterBasedOnSelectionForExclusion(entity.children);
				const processedEntity = processEntityBasedOnChildren(entity, filteredResult);
				if (processedEntity) {
					mappedEntities.push(processedEntity);
				}
			} else {
				if (!selected.includes(entity.code)) {
					mappedEntities.push({ ...entity, icon: selectionIcon });
				}
			}
		})
		return mappedEntities;
	}

	const addSelectionIcon = (locations: any[]) => {
		const locationsWithIcon: any[] = locations.map(location => {
			if (location.children && location.children.length > 0) {
				return { ...location, icon: selectionIcon, children: addSelectionIcon(location.children) };
			}
			return { ...location, icon: selectionIcon };
		});
		return locationsWithIcon;
	}

	if ((selected[0] === dimensionIncludeValues.ALL_INCLUDED)) {
		if (type === "inclusion") {
			return addSelectionIcon(regions);
		} else {
			return [];
		}
	}

	if (type === "inclusion") {
		return filterBasedOnSelectionForInclusion(regions);
	} else {
		return filterBasedOnSelectionForExclusion(regions);
	}
}

export const getLeafValues = (tree: any[]) => {
	let selectedValues: string[] = [];
	tree.forEach(node => {
		if (!node.children?.length) {
			selectedValues.push(node.code);
		} else {
			selectedValues.push(...getLeafValues(node.children));
		}
	});
	return selectedValues;
}

export const getLeafValueObjects = (tree: any[]) => {
	let selectedValues: CodeAndName[] = [];
	tree.forEach(node => {
		if (!node.children?.length) {
			selectedValues.push({code: node.code, name: node.name});
		} else {
			selectedValues.push(...getLeafValueObjects(node.children));
		}
	});
	return selectedValues;
}

import React, { useEffect, useMemo, useState } from "react";
import styles from "./PickListRegion.module.scss";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import RegionTree from "../RegionTree/RegionTree";
import { getLeafValueObjects, getLeafValues } from "../ScopeComponents/ScopeHelper";
import { dimensionIncludeValues } from "../utils/dimensionData";
import SpinnerComponent from "../Spinner/SpinnerComponent";

export interface PickListRegionProperties {
  mainHeader?: string,
  sourceValues: any[],
  subMainTree?: any[],
  sourceTree?: any[],
  targetTree?: any[],
  sourceHeader?: string,
  targetHeader?: string,
  searchPlaceholder?: string,
  searchKey: string,
  onChange: Function
}

export const PickListRegion: React.FC<PickListRegionProperties> =
  ({
    sourceValues = [],
    subMainTree = [],
    sourceTree = [],
    targetTree = [],
    sourceHeader = "Source",
    targetHeader = "Target",
    searchPlaceholder = "Search",
    onChange
  }) => {
    const [searchSource, setSearchSource] = useState("");
    const [searchTarget, setSearchTarget] = useState("");
    const [selectedSource, setSelectedSource] = useState([] as string[]);
    const [selectedTarget, setSelectedTarget] = useState([] as string[]);
    const [showSpinner, setShowSpinner] = useState(false);

    useEffect(() => {
      setSelectedSource([]);
      setSelectedTarget([]);
    }, [subMainTree])


    const getSourceItems = () => {
      return (
        subMainTree && sourceTree.length > 0 ?
          <>
            <RegionTree selectedValuesOnTree={selectedSource || []} filterValue={searchSource} enableFilter={true}
              saveSelection={(value: any) => setSelectedSource(value)}
              editMode={true} nodes={sourceTree} />
          </>
          :
          ""
      );
    };

    const getTargetItems = () => {
      return (
        subMainTree && targetTree.length > 0 ?
          <>
            <RegionTree selectedValuesOnTree={selectedTarget || []} filterValue={searchTarget} enableFilter={true}
              saveSelection={(value: any) => setSelectedTarget(value)}
              editMode={true} nodes={targetTree} />
          </> :
          ""
      );
    };

    const moveSourceToTarget = () => {
      const exclusionNodes = ["EXCLUDED"];// dummy value to mark some elements are excluded

      let allSourceValues = [];
      if (sourceValues[0] && sourceValues[0] === dimensionIncludeValues.ALL_INCLUDED) {
        allSourceValues = getLeafValues(sourceTree);
      } else {
        allSourceValues = sourceValues;
      }

      const newSourceValues = allSourceValues.filter(value => !selectedSource.includes(value));
      setShowSpinner(false);

      onChange(newSourceValues, exclusionNodes);
      setSelectedSource([]);
    };

    const moveTargetToSource = () => {
      let newSourceValues = [...sourceValues, ...selectedTarget];
      onChange(newSourceValues);
      setSelectedTarget([]);
      setShowSpinner(false);
    };

    const processItems = (searchItem: String, treeItems: any) => {
      const allValues = getLeafValueObjects(treeItems);
      if(searchItem !== ""){
        return allValues.filter(value => value.name.toLowerCase().includes(searchItem.toLowerCase())).map(value => value.code);
      }
      else{
        return allValues.map(value => value.code);
      }
    }
  
    const processSourceItems = useMemo(() => processItems(searchSource, sourceTree), [searchSource, sourceTree]);
    const processTargetItems = useMemo(() => processItems(searchTarget, targetTree), [searchTarget, targetTree]);

    const selectAllSource = () => {
      setSelectedSource(processSourceItems);
    };

    const deSelectAllSource = () => {
      setSelectedSource([]);
    };

    const selectAllTarget = () => {
      setSelectedTarget(processTargetItems);
    };

    const deSelectAllTarget = () => {
      setSelectedTarget([]);
    };

    return (
      <>
        {showSpinner && <SpinnerComponent />}
        {
          <div className={styles.pickListRegionContainer}>
            <div className={styles.columnContent}>
              <div>
                <h3>{sourceHeader}</h3>
              </div>
              <div className={styles.listContent}>
                <div className={styles.searchBox}>
                  <span className={`p-input-icon-left ${searchSource !== "" && "p-input-icon-right"}`}>
                    <i className={`pi pi-search ${styles.searchIcon}`} />

                    <InputText className={styles.searchInput} placeholder={searchPlaceholder}
                      value={searchSource}
                      onChange={(e) => setSearchSource(e.target.value)} />
                    {
                      searchSource &&
                      <i className={`pi pi-times ${styles.clearSearch}`} onClick={() => setSearchSource("")} />
                    }
                  </span>
                </div>
                <div className={`${styles.columnContentBody} noDefaultTreeFilter`}>


                  <div className={styles.pickListItems}>
                    <div className={styles.allItemsControl}>
                      <span onClick={() => selectAllSource()}> Select All</span>
                      <span onClick={() => deSelectAllSource()}> Deselect All</span>
                    </div>
                    {getSourceItems()}
                  </div>
                </div>
              </div>


            </div>
            <div className={styles.arrowControls}>
              <div>
                <Button icon="pi pi-angle-right" disabled={selectedSource.length === 0} className="p-button-outlined" onClick={() => { setShowSpinner(true); setTimeout(() => moveSourceToTarget(), 0) }} />
              </div>
              <div>
                <Button icon="pi pi-angle-left" disabled={selectedTarget.length === 0} className="p-button-outlined" onClick={() => { setShowSpinner(true); setTimeout(() => moveTargetToSource(), 0) }} />
              </div>
            </div>
            <div className={styles.columnContent}>
              <div>
                <h3>{targetHeader}</h3>
              </div>
              <div className={`p-input-icon-left ${searchTarget !== "" && "p-input-icon-right"}`}>
                <i className={`pi pi-search ${styles.searchIcon}`} />

                <InputText className={styles.searchInput} placeholder={searchPlaceholder}
                  value={searchTarget}
                  onChange={(e) => setSearchTarget(e.target.value)} />
                {
                  searchTarget &&
                  <i className={`pi pi-times ${styles.clearSearch}`} onClick={() => setSearchTarget("")} />
                }

              </div>
              <div className={`${styles.columnContentBody} noDefaultTreeFilter`}>


                <div className={styles.pickListItems}>
                  <div className={styles.allItemsControl}>
                    <span onClick={() => selectAllTarget()}> Select All</span>
                    <span onClick={() => deSelectAllTarget()}> Deselect All</span>
                  </div>
                  {getTargetItems()}
                </div>
              </div>
            </div>
          </div>
        }
      </>)
  };

import { FC, useContext, useState } from 'react';
import styles from './SolutionsComponentsAndFeaturesView.module.scss';
import ComponentAccordion from '../../components/ComponentAccordion/ComponentAccordion';
import { USER_ACTION, SolutionDefinition, SolutionDefnitionRequest, FeatureAssociation, FeatureAssociationRequest, SpecificationParameterDefinition, SpecificationParameterAssociation, SolutionType, SolutionComponentOrigin, ComponentAssociationRequest, ComponentDefinition, FeatureDefinition, ComponentAssociation, SolutionProductRelationTypeChangeDTO, ProductListing } from '../../data/model/DataModels';
import AssociationDetailsComponent from '../../components/AssociationDetailsComponent/AssociationDetailsComponent';
import { useUpdateSolutionMutation, useUpdateSolutionProductMutation } from '../../data/api/CatalogueApi';
import SpinnerComponent from '../../components/Spinner/SpinnerComponent';
import { SolutionContext, constructBaseSolutionRequest } from '../SolutionDetails/SolutionDetails';
import { ToasterContext } from '../AppLayoutView/AppLayoutView';
import { Button } from 'primereact/button';
import ComponentReorderView from '../ProductView/ProductComponents/ComponentReorderView/ComponentReorderView';
import { DisplayCardElement } from '../../components/DisplayBigCards/DisplayBigCards';
import SolutionAddComponentFromProductsDialog from '../SolutionAddComponentFromProductsDialog/SolutionAddComponentFromProductsDialog';
import SolutionAddComponentDialog from '../SolutionAddComponentDialog/SolutionAddComponentDialog';
import AddSolutionComponentDialog from '../../components/AddSolutionComponentDialog/AddSolutionComponentDialog';
import { getFeatureAssociationRequestsFromFeatureDefinitions, getSpecParamAssociationRequestsFromSpecParamDefinitions } from '../../FeatureAndComponentUtils';
import FeatureUpgradeDialog from '../../components/FeatureUpgradeDialog/FeatureUpgradeDialog';
import FeatureDetailPanel from '../../components/FeatureDetailPanel/FeatureDetailPanel';
import { cloneDeep } from 'lodash';

export interface SolutionsComponentsAndFeaturesViewProps {
  refetchSolution: Function
  isEditable?: boolean
  setFeatureInEditMode: Function
  featureInEditMode: boolean
}

const SolutionsComponentsAndFeaturesView: FC<SolutionsComponentsAndFeaturesViewProps> = ({
  refetchSolution,
  isEditable = false,
  featureInEditMode,
  setFeatureInEditMode
}) => {
  const [updateSolution, { isLoading: isLoadingUpdateSolution }] = useUpdateSolutionMutation();
  const solutionState = useContext(SolutionContext);
  const solution = solutionState.solutionData;
  const [componentOrigin, setComponentOrigin] = useState<SolutionComponentOrigin>();
  const [reorderEnabled, setReorderEnabled] = useState(false);
  const [showComponentOriginSelection, setShowComponentOriginSelection] = useState(false);
  const [reorderedList, setReorderedList] = useState([] as DisplayCardElement[]);
  const [selectedFeatureForUpgrade, setSelectedFeatureForUpgrade] = useState<FeatureAssociation>();
  const [selectedSpecParamCode, setSelectedSpecParamCode] = useState('' as string);
  const [openFeatureDetailSidePanelFor, setOpenFeatureDetailSidePanelFor] = useState<FeatureAssociation>()
  const [userAction, setUserAction] = useState(USER_ACTION.EXPAND_ALL);
  const [updateSolutionProduct, { isLoading: isLoadingUpdateSolutionProduct }] = useUpdateSolutionProductMutation();
  const toaster = useContext(ToasterContext);

  if (!solution) {
    return <></>
  }

  const addComponentsAndFeaturesToSolution = (components: ComponentDefinition[], features: FeatureDefinition[]) => {

    const componentAssociations: ComponentAssociationRequest[] = [
      ...components.map((component) => {
        return {
          componentRef: component.code
        } as ComponentAssociationRequest
      }),
      ...solution.components
    ];

    const allSpecificationParameters = features.reduce<SpecificationParameterDefinition[]>((accumulator, current) => [...accumulator, ...current.specificationParameters], [] as SpecificationParameterDefinition[]);

    const solutionBaseRequest = constructBaseSolutionRequest(solution);
    const solutionRequestData: SolutionDefnitionRequest = {
      ...solutionBaseRequest,
      components: componentAssociations,
      features: [...solution.features, ...getFeatureAssociationRequestsFromFeatureDefinitions(features)],
      specificationParameters: [...solution.specificationParameters, ...getSpecParamAssociationRequestsFromSpecParamDefinitions(allSpecificationParameters)]
    };

    updateSolution(solutionRequestData).unwrap().then(
      (response) => {
        const responseToBeUpdated = {
          ...solution,
          lockingVersion: response.lockingVersion,
          components: response.components,
          features: response.features,
          specificationParameters: response.specificationParameters
        };
        solutionState.setSolutionData(responseToBeUpdated);
        toaster.showToast('success', 'Successfully added components');
        setComponentOrigin(undefined);
      },
      (error) => {
        let errorMsg: string = error.data.message;
        errorMsg = errorMsg.includes("UM:") ? errorMsg.split("UM: ")[1] : 'Failed to add components';
        toaster.showToast('error', errorMsg);
      }
    );
  }

  const showComponentSelection = () => {
    if (componentOrigin === SolutionComponentOrigin.COMPONENT_FROM_PRODUCT) {
      return <SolutionAddComponentFromProductsDialog onHide={() => setComponentOrigin(undefined)}
        existingComponents={solution.components}
        saveProps={{ save: addComponentsAndFeaturesToSolution, isLoadingSave: isLoadingUpdateSolution }}
      />
    } else if (componentOrigin === SolutionComponentOrigin.COMPONENT_LIBRARY) {
      return <SolutionAddComponentDialog onHide={() => setComponentOrigin(undefined)}
        existingComponents={solution.components}
        saveProps={{ save: addComponentsAndFeaturesToSolution, isLoadingSave: isLoadingUpdateSolution }}
      />
    }
    return <></>
  }

  const deleteComponentFromSolution = (componentRef: string) => {
    const solutionBaseRequest = constructBaseSolutionRequest(solution);
    const solutionRequestData: SolutionDefnitionRequest = {
      ...solutionBaseRequest,
      components: solution.components.filter(component => component.componentRef !== componentRef),
      features: solution.features.filter(feature => feature.componentRef !== componentRef),
      specificationParameters: solution.specificationParameters.filter(specParam => specParam.componentRef !== componentRef)
    };

    updateSolution(solutionRequestData).unwrap().then(
      (response) => {
        const updatedSolution: SolutionDefinition = {
          ...solution,
          lockingVersion: response.lockingVersion,
          components: response.components,
          features: response.features,
          specificationParameters: response.specificationParameters
        }
        solutionState.setSolutionData(updatedSolution);
        toaster.showToast('success', 'Successfully deleted component from solution');
      },
      () => {
        toaster.showToast('error', 'Failed to delete component from solution');
      }
    );
  }

  const deleteFeatureFromSolution = (featureCode: string) => {
    const solutionBaseRequest = constructBaseSolutionRequest(solution);
    const solutionRequestData: SolutionDefnitionRequest = {
      ...solutionBaseRequest,
      features: solution.features.filter(feature => feature.featureRef !== featureCode),
      specificationParameters: solution.specificationParameters.filter(specParam => specParam.featureRef !== featureCode)
    };

    updateSolution(solutionRequestData).unwrap().then(
      (response) => {
        const updatedSolution: SolutionDefinition = {
          ...solution,
          lockingVersion: response.lockingVersion,
          features: response.features,
          specificationParameters: response.specificationParameters
        }
        solutionState.setSolutionData(updatedSolution);
        toaster.showToast('success', 'Successfully deleted feature from solution');
      },
      () => {
        toaster.showToast('error', 'Failed to delete feature from solution');
      }
    );
  }
  const addFeatures = (features: FeatureAssociation[]) => {

    const featureAssociations: FeatureAssociationRequest[] = [...features, ...solution.features];
    const allSpecificationParameters = features.map(feature => feature.definition.specificationParameters).reduce<SpecificationParameterDefinition[]>((accumulator, current) => [...accumulator, ...current], [] as SpecificationParameterDefinition[]);

    const solutionBaseRequest = constructBaseSolutionRequest(solution);
    const solutionRequestData: SolutionDefnitionRequest = {
      ...solutionBaseRequest,
      features: featureAssociations,
      specificationParameters: [...solution.specificationParameters, ...getSpecParamAssociationRequestsFromSpecParamDefinitions(allSpecificationParameters)]
    };

    updateSolution(solutionRequestData).unwrap().then(
      (response) => {
        const updatedSolution: SolutionDefinition = {
          ...solution,
          lockingVersion: response.lockingVersion,
          features: response.features,
          specificationParameters: response.specificationParameters
        }
        solutionState.setSolutionData(updatedSolution);
        toaster.showToast('success', `Successfully added ${features.length > 0 ? "features" : "feature"}`);
      },
      () => {
        toaster.showToast('error', `Failed to add ${features.length > 0 ? "features" : "feature"}`);
      }
    );
  }

  const updateProductInSolution = (relationType: string, productCode: string) => {
    const solutionProductUpgradeRequestData: SolutionProductRelationTypeChangeDTO = { solutionId: solution.id, productCode: productCode, relationType: relationType };
    updateSolutionProduct(solutionProductUpgradeRequestData).unwrap().then(
      (response: ProductListing) => {
        solutionState.setSolutionData(
          {
            ...solution,
            products: solution.products.map(product => (product.code === response.code) ? { ...response, relationType: relationType } : product)
          });
        toaster.showToast('success', 'Product is updated successfully');
      },
      () => {
        toaster.showToast('error', 'Failed to update the product');
      }
    )
  }

  const onFeatureTitleClick = (featureCode: string) => {
    setOpenFeatureDetailSidePanelFor(solution.features.find(feature => feature.featureRef === featureCode));
  }

  const onSpecParamTitleClick = (featureCode: string, specParamCode: string) => {
    setOpenFeatureDetailSidePanelFor(solution.features.find(feature => feature.featureRef === featureCode));
    setSelectedSpecParamCode(specParamCode);
  }

  const showSelectedView = () => {
    if (userAction === USER_ACTION.VIEW_DETAILS) {
      return <AssociationDetailsComponent
        isEditable={isEditable}
        setFeatureInEditMode={setFeatureInEditMode}
        featureInEditMode={featureInEditMode}
        refetchSolution={() => refetchSolution()}
        deleteComponent={(componentCode: string) => { deleteComponentFromSolution(componentCode) }}
        addFeatures={(features: FeatureAssociation[]) => addFeatures(features)}
        isNewFeatureVersionAvailable={isNewFeatureVersionAvailable}
        setSelectedFeatureForUpgrade={setSelectedFeatureForUpgrade}
        updateProduct={(relationType: string, productCode: string) => updateProductInSolution(relationType, productCode)} />
    } else {
      return <ComponentAccordion
        componentList={solution.components}
        featureList={solution.features}
        specificationParameterList={solution.specificationParameters}
        isEditable={isEditable}
        index={0}
        expandAll={userAction === USER_ACTION.EXPAND_ALL ? true : false}
        updateParent={() => { }}
        deleteComponent={(componentCode: string) => { deleteComponentFromSolution(componentCode) }}
        deleteFeature={(featureCode: string) => { deleteFeatureFromSolution(featureCode) }}
        deleteSpecificationParameter={(specParamCode: string) => deleteSpecification(specParamCode)}
        addFeatures={(features: FeatureAssociation[]) => addFeatures(features)}
        isNewFeatureVersionAvailable={isNewFeatureVersionAvailable}
        setSelectedFeatureForUpgrade={setSelectedFeatureForUpgrade}
        onFeatureTitleClick={(featureCode: string) => onFeatureTitleClick(featureCode)}
        onSpecParamTitleClick={(featureCode: string, specParamCode: string) => onSpecParamTitleClick(featureCode, specParamCode)}
      />
    }
  }

  const reOrderComponentsAccordingToList = (orderedList: DisplayCardElement[]): ComponentAssociation[] => {
    let orderedComponentList = [] as ComponentAssociation[];
    orderedList.forEach(listItem => {
      const component = solution.components.find(component => component.componentRef === listItem.code);
      if (component) {
        orderedComponentList.push(component);
      }
    });
    return orderedComponentList;
  }

  const saveOrdering = (elements: DisplayCardElement[]) => {
    const solutionBaseRequest = constructBaseSolutionRequest(solution);
    const solutionRequestData: SolutionDefnitionRequest = {
      ...solutionBaseRequest,
      components: reOrderComponentsAccordingToList(elements)
    };

    updateSolution(solutionRequestData).unwrap().then(
      (response) => {
        const responseToBeUpdated = {
          ...solution,
          lockingVersion: response.lockingVersion,
          components: response.components
        };
        solutionState.setSolutionData(responseToBeUpdated);
        toaster.showToast('success', 'Successfully reordered components');
        setReorderEnabled(false);
        setUserAction(USER_ACTION.NONE);
      },
      () => {
        toaster.showToast('error', 'Failed to reorder components');
      }
    );
  }

  const mapComponentToElements = (components: ComponentAssociation[]): DisplayCardElement[] => {
    return components.map(component => {
      const cardElement: DisplayCardElement = {
        name: component.definition.name,
        code: component.componentRef,
        description: component.definition.description,
        descriptionRichText: component.definition.descriptionRichText,
        tooltipElements: solution?.features.filter(feature => feature.componentRef === component.componentRef).map(feature => feature?.definition ? feature?.definition.name : ""),
        latestVersionFeatures: component.upgradedFeatureCode?.map(({ code }) => code)
      };
      return cardElement;
    });
  }

  const showReorderingButtons = () => {
    return <>
      <Button label="Cancel" data-testid="cancelReorderingButton" icon="pi pi-times" className="p-button-outlined p-button-secondary"
        onClick={() => { setReorderEnabled(false); setUserAction(USER_ACTION.NONE) }} />
      <Button label="Done reordering" data-testid="doneReorderingButton" icon="pi pi-check"
        onClick={() => saveOrdering(reorderedList)} />
    </>
  }

  const newFeatureToUpdateSp = (newFeature: FeatureDefinition, existingFeature: FeatureDefinition) => {
    const updatedSPs = newFeature.specificationParameters.map(newFeatureSP => {
      const existingFeatureSP = existingFeature.specificationParameters.find(existingSP => newFeatureSP.code === existingSP.code);
      const updatedServiceDefault = existingFeatureSP?.serviceDefault ?? newFeatureSP.serviceDefault;
      return { ...newFeatureSP, serviceDefault: updatedServiceDefault };
    });
    return { ...newFeature, specificationParameters: updatedSPs };
  };

  const upgradeToNewFeature = (existingFeature: FeatureDefinition, newFeature: FeatureDefinition) => {
    const updateFeatureDefinition = (newFeature.service != existingFeature.service) ? newFeature : newFeatureToUpdateSp(newFeature, existingFeature);

    const newUpgradedFeature = {
      productId: solution?.id.toString(),
      featureRef: existingFeature.code,
      componentRef: existingFeature.componentRef,
      definition: updateFeatureDefinition,
      featureVersion: newFeature.version,
      service: (newFeature.service != existingFeature.service) ? newFeature.service : existingFeature.service,
      serviceDefault: (newFeature.service != existingFeature.service) ? newFeature.serviceDefault : existingFeature.serviceDefault
    } as FeatureAssociation;

    let upgradedFeatures = solution.features.map((feature) =>
      feature.featureRef === existingFeature.code ? newUpgradedFeature : feature
    );

    const convertSpecParamDefinitionToAssociation = (specParam: SpecificationParameterDefinition, previousSPAssociationId?: number): SpecificationParameterAssociation => {
      const updateAssociation: SpecificationParameterAssociation = {
        featureRef: specParam.featureRef,
        componentRef: specParam.componentRef,
        specificationParamRef: specParam.code,
        configuration: specParam.configuration,
        productId: solution?.id,
        definition: specParam,
        configurableAtContracting: specParam.configurableAtContracting,
        configurableAtBooking: specParam.configurableAtBooking,
        featureVersion: specParam.featureVersion,
        serviceDefault: specParam.serviceDefault,
      } as SpecificationParameterAssociation
      if (previousSPAssociationId) {
        updateAssociation.id = previousSPAssociationId;
      }
      return updateAssociation;
    }

    const existingSpecParamToBeDeleted =
      existingFeature.specificationParameters.map((specParam) =>
        convertSpecParamDefinitionToAssociation(specParam)
      );

    const newSpecParamToBeAdded = newFeature.specificationParameters.map((specParam) => {
      const existingFeatureSP = solution.specificationParameters.find(existingSP =>
        existingSP.specificationParamRef === specParam.code
      );
      let newSpecParamDefinition: SpecificationParameterDefinition = { ...specParam };
      if (newFeature.service == existingFeature.service) {
        const areNewSPCoreAndExistingFeatureOptional = (existingFeatureSP == undefined) && (specParam.serviceDefault === "included") && (existingFeature.serviceDefault == "not_included");
        if (areNewSPCoreAndExistingFeatureOptional) {
          newSpecParamDefinition.serviceDefault = "not_included";
        } else {
          newSpecParamDefinition.serviceDefault = existingFeatureSP?.serviceDefault ?? specParam.serviceDefault
        }
      }
      const convertedSpecParam = convertSpecParamDefinitionToAssociation(newSpecParamDefinition, existingFeatureSP?.id);
      return convertedSpecParam;
    });

    const updatedSpecificationparameters =
      solution.specificationParameters.filter(
        (mainSp) =>
          !existingSpecParamToBeDeleted.filter(
            (spToDelete) =>
              spToDelete.specificationParamRef ===
              mainSp.specificationParamRef &&
              mainSp.featureRef === spToDelete.featureRef
          ).length
      );

    const solutionBaseRequest = constructBaseSolutionRequest(solution);
    const solutionRequestData: SolutionDefnitionRequest = {
      ...solutionBaseRequest,
      features: upgradedFeatures,
      specificationParameters: [...updatedSpecificationparameters, ...newSpecParamToBeAdded]
    };

    updateSolution(solutionRequestData).unwrap().then(
      (response) => {
        solutionState.setSolutionData(
          {
            ...solution as SolutionDefinition,
            lockingVersion: response.lockingVersion,
            specificationParameters: response.specificationParameters,
            features: response.features
          }
        );
        toaster.showToast('success', 'Successfully upgraded feature');
      },
      () => {
        toaster.showToast('error', 'Failed to upgrade feature');
      }
    );
  };

  const isNewFeatureVersionAvailable = (feature: FeatureDefinition): boolean => {
    const filteredComponent = solution.components.filter(comp => comp.componentRef === feature.componentRef);
    if (filteredComponent) {
      const newFeatureVersion = filteredComponent.flatMap(comp => comp.upgradedFeatureCode?.filter(featureCodeAndVersion => featureCodeAndVersion.code === feature.code));
      const latestVersionAvailable = newFeatureVersion[0]?.featureVersion;
      return latestVersionAvailable && (latestVersionAvailable > feature.version) ? true : false;
    }
    return false;
  }

  const getSpecificationParameterDefinitions = (featureAssociation: FeatureAssociation) => {
    return solution.specificationParameters.filter(specParam => specParam.featureRef === featureAssociation.featureRef).map(spAssociation => {
      return {
        ...spAssociation,
        code: spAssociation.specificationParamRef,
      } as unknown as SpecificationParameterDefinition
    })
  }

  const deleteSpecification = (specificationParamRef: string) => {
    const solutionRequestData: SolutionDefnitionRequest = {
      ...constructBaseSolutionRequest(solution),
      features: solution.features,
      specificationParameters: solution.specificationParameters.filter(sp => sp.specificationParamRef !== specificationParamRef)
    };

    updateSolution(solutionRequestData).unwrap().then(
      (response) => {
        const responseToBeUpdated = {
          ...solution,
          lockingVersion: response.lockingVersion,
          features: response.features,
          specificationParameters: response.specificationParameters
        };
        solutionState.setSolutionData(responseToBeUpdated);
        toaster.showToast('success', 'Successfully deleted specification parameter');
      },
      (error) => {
        let errorMsg: string = error.data.message;
        errorMsg = errorMsg.includes("UM:") ? errorMsg.split("UM: ")[1] : 'Failed to deleted specification parameter';
        toaster.showToast('error', errorMsg);
      }
    );
  }

  const closeFeatureSidePanel = () => {
    setOpenFeatureDetailSidePanelFor(undefined);
    setSelectedSpecParamCode('');
  }

  const saveFeatureChanges = (updatedFeature: FeatureAssociation, updatedSPs: SpecificationParameterDefinition[]) => {
    const solutionRequestData: SolutionDefnitionRequest = {
      ...constructBaseSolutionRequest(solution),
      features: solution.features,
      specificationParameters: solution.specificationParameters
    };

    // process updates on feature

    if (updatedFeature) {
      let featureListCopy = cloneDeep(solution.features);
      solutionRequestData.features = featureListCopy.map(feature => {
        if (feature.featureRef === updatedFeature.featureRef) {
          return { ...feature, ...updatedFeature };
        }
        return feature;
      });
    }


    // process updates on SPs
    if (updatedSPs) {
      const updatedSpAssociations = getSpecParamAssociationRequestsFromSpecParamDefinitions(updatedSPs);
      let spListCopy = cloneDeep(solution.specificationParameters);

      updatedSpAssociations.forEach(updatedSpAssociation => {
        const index = spListCopy.findIndex(sp => sp.specificationParamRef === updatedSpAssociation.specificationParamRef);
        if (index !== -1) {
          updatedSpAssociation.id = solution.specificationParameters[index].id;
          spListCopy[index] = updatedSpAssociation as SpecificationParameterAssociation;
        } else {
          spListCopy.push(updatedSpAssociation as SpecificationParameterAssociation);
        }
      });

      solutionRequestData.specificationParameters = spListCopy;
    }


    updateSolution(solutionRequestData).unwrap().then(
      (response) => {
        const responseToBeUpdated = {
          ...solution,
          lockingVersion: response.lockingVersion,
          features: response.features,
          specificationParameters: response.specificationParameters
        };
        solutionState.setSolutionData(responseToBeUpdated);
        toaster.showToast('success', 'Successfully updated feature');
      },
      (error) => {
        let errorMsg: string = error.data.message;
        errorMsg = errorMsg.includes("UM:") ? errorMsg.split("UM: ")[1] : 'Failed to update feature';
        toaster.showToast('error', errorMsg);
      }
    );
  }
  return (
    <div className={styles.SolutionsComponentsAndFeaturesView} data-testid="SolutionsComponentsAndFeaturesView">
      {(isLoadingUpdateSolution || isLoadingUpdateSolutionProduct) && <SpinnerComponent />}
      <div className={styles.productsHeader}>
        <div className={styles.productCountDropdown}>
          {solution.productType === SolutionType.PACKAGED_SOLUTION ?
            <h2 data-testid="totalproduct">Products ({solution?.products.length})</h2> :
            <h2 data-testid="totalcomponent">Components ({solution?.components.length})</h2>
          }
        </div>
        {isEditable && <div className={styles.buttonSection}>
          {
            reorderEnabled ?
              showReorderingButtons()
              :
              <>
                <Button data-testid='addComponentButton' label="Add Component" icon="pi pi-plus" className={`p-button-outlined p-button-secondary ${styles.leftAlignedButtons}`}
                  onClick={() => { setShowComponentOriginSelection(true) }} />
                <Button data-testid='reOrderButton' label="Reorder" icon="pi pi-bars"
                  onClick={() => setReorderEnabled(true)} />
              </>
          }
        </div>
        }
      </div>
      {
        reorderEnabled ?
          <ComponentReorderView elementList={mapComponentToElements(solution.components)} saveOrdering={(components: DisplayCardElement[]) => setReorderedList(components)} />
          :
          <>
            <div className={styles.actionSection}>
              <div onClick={() => setUserAction(USER_ACTION.EXPAND_ALL)} className={styles.actionIconLabel}><i className="pi pi-angle-down" data-testid="expand-all-link" /><span className={`${(userAction === USER_ACTION.EXPAND_ALL || userAction === USER_ACTION.NONE) ? styles.underline : ''}`}>Expand All View</span></div>
              <div onClick={() => setUserAction(USER_ACTION.COLLAPSE_ALL)} className={styles.actionIconLabel}><i className="pi pi-angle-right" data-testid="collapse-all-link" /><span className={`${userAction === USER_ACTION.COLLAPSE_ALL ? styles.underline : ''}`}>Collapse All View</span></div>
              <div onClick={() => setUserAction(USER_ACTION.VIEW_DETAILS)} className={styles.actionIconLabel}><i className="pi pi-eye" data-testid="view-details-link" /><span className={`${userAction === USER_ACTION.VIEW_DETAILS ? styles.underline : ''}`}>Details View</span></div>
            </div>
            {
              showSelectedView()
            }
          </>
      }
      {
        showComponentOriginSelection &&
        <AddSolutionComponentDialog setDialogVisibleFn={(componentType: SolutionComponentOrigin) => { setComponentOrigin(componentType); setShowComponentOriginSelection(false) }} />
      }
      {
        openFeatureDetailSidePanelFor !== undefined &&
        <FeatureDetailPanel
          isEditable={isEditable}
          onHide={() => closeFeatureSidePanel()}
          feature={openFeatureDetailSidePanelFor}
          saveChanges={(updatedFeature: FeatureAssociation, updatedSPs: SpecificationParameterDefinition[]) => saveFeatureChanges(updatedFeature, updatedSPs)}
          specificationParameters={getSpecificationParameterDefinitions(openFeatureDetailSidePanelFor)}
          selectedSpecParamCode={selectedSpecParamCode}
          deleteSpecification={(specParamRef: string) => deleteSpecification(specParamRef)}
        />
      }
      {showComponentSelection()}
      {
        selectedFeatureForUpgrade !== undefined &&
        <FeatureUpgradeDialog onHide={() => setSelectedFeatureForUpgrade(undefined)} feature={selectedFeatureForUpgrade} specificationParameters={getSpecificationParameterDefinitions(selectedFeatureForUpgrade)} isEditable={isEditable}
          upgradeToNewFeature={(existingFeature: FeatureDefinition, newFeature: FeatureDefinition) => upgradeToNewFeature(existingFeature, newFeature)} />
      }
    </div>
  )

}

export default SolutionsComponentsAndFeaturesView;

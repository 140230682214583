import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { FC, useContext, useEffect, useState } from "react";
import styles from './DimensionsDialogBox.module.scss';
import SpinnerComponent from "../Spinner/SpinnerComponent";
import { DimensionDefinition } from "../../data/model/DataModels";
import { ToasterContext } from "../../features/AppLayoutView/AppLayoutView";
import { useGetDimensionTypesQuery } from "../../data/api/RefDataApi";
import { cloneDeep } from "lodash";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { getDimensionIcon } from "../ScopeComponents/ScopeHelper";

export interface DimensionsDialogBoxProps {
	headerName: string,
	descriptionText: Function,
	applyDimensions: Function,
	savedDimensionsData: string[],
	closeDimensionDialogBox: Function,
	defaultDimensionsData: string[],
	productDimensionsData: string[],
	locationConfigurationFlag?: boolean,
	originDestinationConfigurationFlag?: boolean
}

const DimensionsDialogBox: FC<DimensionsDialogBoxProps> = ({ headerName, descriptionText, applyDimensions, closeDimensionDialogBox, savedDimensionsData, defaultDimensionsData, productDimensionsData, locationConfigurationFlag = false, originDestinationConfigurationFlag = false }) => {
	const { data: allDimensionsData = [], error: errorAllDimensionsData, isLoading: isLoadingAllDimensionsData } = useGetDimensionTypesQuery();
	const [searchInput, setSearchInput] = useState("");
	const [allDimensions, setAllDimensions] = useState([] as DimensionDefinition[]);
	const [selectedDimensions, setSelectedDimensions] = useState(savedDimensionsData);
	const toaster = useContext(ToasterContext);

	useEffect(() => {
		if (allDimensionsData && allDimensionsData.length > 0 && allDimensions.length === 0) {
			setAllDimensions(allDimensionsData);
		}
	}, [allDimensionsData]);


	useEffect(() => {
		const newlyAddedDefaultDimensions = defaultDimensionsData.filter(dimension => !savedDimensionsData.includes(dimension));
		setSelectedDimensions([...savedDimensionsData, ...newlyAddedDefaultDimensions]);
	}, [savedDimensionsData]);

	useEffect(() => {
		setAllDimensions(allDimensionsData.filter(data => data.name.toLowerCase().includes(searchInput.toLowerCase())));
	}, [searchInput]);


	if (errorAllDimensionsData) {
		toaster.showToast('error', `Failed to load dimensions`);
	}


	const onStatusChange = (code: string) => {
		const selectedDimension = allDimensions.find(dimension => dimension.code === code);
		if (selectedDimension) {
			selectedDimensions.includes(selectedDimension.code) ?
				setSelectedDimensions(selectedDimensions.filter(dimensionCode => dimensionCode !== selectedDimension.code)) :
				setSelectedDimensions([selectedDimension.code, ...selectedDimensions]);
		}
	}

	const isToggleDisabled = (dimension: DimensionDefinition) => {
		return isDefaultDimension(dimension) || isAlreadySelectedProductDimension(dimension.code);
	}

	const isAlreadySelectedProductDimension = (dimensionCode: string) => {
		return productDimensionsData.includes(dimensionCode);
	}
	const isSelectedDimension = (dimensionCode: string) => {
		return [...productDimensionsData, ...selectedDimensions].includes(dimensionCode);
	}


	const isDefaultDimension = (dimension: DimensionDefinition) => {
		return defaultDimensionsData.includes(dimension.code);
	}

	const getExamples = (examples: string[]) => {
		let examplesCopy = cloneDeep(examples);
		if (examplesCopy.length === 1) {
			return <>{examplesCopy[0].concat(" etc.")}</>
		}
		if (examplesCopy.length >= 2) {
			examplesCopy.length = 2;
			return <>{examplesCopy.join(", ").concat(" etc.")}</>
		}

	}

	const checkDimensionDisabilityAndProvideReasonAndStyle = (dimension: DimensionDefinition): { isDisabled: boolean, reason: string, cardStyle: string } => {
		if (isDefaultDimension(dimension)) {
			return { isDisabled: true, reason: "You cannot remove a default dimension", cardStyle: styles.disableDimensionThumbnail };
		}
		if (isAlreadySelectedProductDimension(dimension.code)) {
			return { isDisabled: true, reason: "You cannot remove dimensions from here", cardStyle: styles.selectedDimensionThumbnail };
		}
		//Check for location and origin/destination dimensions
		if (dimension.code == "locations" && (originDestinationConfigurationFlag || (isSelectedDimension("imports") || isSelectedDimension("exports")))) {
			return { isDisabled: true, reason: "You cannot select locations while origins & destinations are configured", cardStyle: styles.disableDimensionThumbnail };
		}
		if ((dimension.code == "imports" || dimension.code == "exports") && (locationConfigurationFlag || isSelectedDimension("locations"))) {
			return { isDisabled: true, reason: "You cannot select origins/destinations while locations are configured", cardStyle: styles.disableDimensionThumbnail };
		}
		return { isDisabled: false, reason: "", cardStyle: "" };
	}

	const dimensionCard = (dimension: DimensionDefinition) => {
		return (
			<div data-testid={dimension.code} key={dimension.code} className={`${styles.dimensionCard} ${checkDimensionDisabilityAndProvideReasonAndStyle(dimension).cardStyle}`}>
				{isLoadingAllDimensionsData && <SpinnerComponent />}
				<div className={styles.headerAndDescription}>
					<div className={styles.header}>
						<div className={styles.imageType2} >
							{getDimensionIcon(dimension.code)}
						</div>
						<div><h3>{dimension.name}</h3></div>
						<div className={styles.actionButton}>
							<InputSwitch data-testid={`${dimension?.code}-switch`} className={isDefaultDimension(dimension) ? styles.inputSwitchDisabled : isToggleDisabled(dimension) ? styles.inputSwitchSelectedDisabled : styles.inputSwitch} checked={selectedDimensions.find(dimensionCode => dimensionCode === dimension.code) !== undefined}
								disabled={checkDimensionDisabilityAndProvideReasonAndStyle(dimension).isDisabled}
								onChange={() => onStatusChange(dimension.code)}
								tooltip={checkDimensionDisabilityAndProvideReasonAndStyle(dimension).reason}
								tooltipOptions={{ showOnDisabled: true, disabled: !checkDimensionDisabilityAndProvideReasonAndStyle(dimension).isDisabled, showEvent: 'click', position: "left" }}
							/>
						</div>
					</div>
					<div className={styles.description}>{dimension.description}</div>
				</div>
				<div className={styles.examples}>
					{getExamples(dimension.examples)}
				</div>
			</div>
		);
	}

	const closeDimension = () => {
		setSelectedDimensions([]);
		closeDimensionDialogBox();
	};

	const footer = (
		<div>
			<Button label="Cancel" className="p-button p-component p-button-outlined p-button-secondary default_button" onClick={() => closeDimension()} />
			<Button label="Apply" onClick={() => applyDimensions(selectedDimensions)} />
		</div>
	);

	return (
		<>
			<Dialog footer={footer} header={headerName} visible={true} onHide={() => closeDimension()} >
				<div className={styles.DefaultDimensions} data-testid="dimensionDialog">
					{
						isLoadingAllDimensionsData && <SpinnerComponent />
					}
					{descriptionText()}
					<div>
						<span className={`p-input-icon-left leftContent ${styles.searchBar}`}>
							<i className="pi pi-search" />
							<InputText className={styles.searchBar} value={searchInput} onChange={(e) => setSearchInput(e.target.value)} placeholder="Search" />
						</span>
					</div>
					<div className={styles.cardGrid}>
						{allDimensions.map((dimension) => {
							return dimensionCard(dimension);
						})}
					</div>
				</div>
			</Dialog>
		</>
	)
}

export default DimensionsDialogBox;


import { FC, useContext, useEffect, useState } from 'react';
import styles from './FeatureAccordion.module.scss';
import { FeatureAssociation, FeatureDefinition, ServiceDefaultLabel, SolutionType, SpecificationParameterAssociation } from '../../data/model/DataModels';
import { Accordion, AccordionTab } from 'primereact/accordion';
import './overrides.scss'
import { SPDefaultLabel } from '../FeatureCardIncremental/SpecificationList/SpecificationList';
import { ReactComponent as FeatureIcon } from '../../assets/icons/maersk/feature-icon.svg';
import { ReactComponent as SpecificationParameterIcon } from '../../assets/icons/maersk/specification-parameter-icon.svg';
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { SolutionContext } from '../../features/SolutionDetails/SolutionDetails';
import { confirmPopup } from 'primereact/confirmpopup';

export interface FeatureAccordionProps {
  featureList: FeatureAssociation[],
  specificationParameterList: SpecificationParameterAssociation[];
  index: number;
  expandAll: boolean;
  isEditable: boolean;
  removeFeature?: Function;
  removeSpecificationParameter?: Function;
  saveFeature?: Function;
  selectedFeatureForEdit: string;
  isNewFeatureVersionAvailable?: Function;
  setSelectedFeatureForUpgrade?: Function;
  onFeatureTitleClick?: Function;
  onSpecParamTitleClick?: Function;
}

const FeatureAccordion: FC<FeatureAccordionProps> = ({ featureList, specificationParameterList, index, expandAll, isEditable, selectedFeatureForEdit, removeFeature, removeSpecificationParameter, isNewFeatureVersionAvailable, setSelectedFeatureForUpgrade, onFeatureTitleClick, onSpecParamTitleClick }) => {

  const [activeIndex, setActiveIndex] = useState(index === 0 ? [index] : []);
  const solutionState = useContext(SolutionContext);
  const solution = solutionState.solutionData;

  useEffect(() => {
    if (expandAll === true) {
      setActiveIndex(featureList.map((feature, index) => index));
    }
    else {
      setActiveIndex([]);
    }
  }, [expandAll])

  if (!solution) {
    return <></>
  }

  const getAssociatedSpecParamsOfFeature = (feature: FeatureAssociation) => {
    return specificationParameterList.filter(specParam => specParam.featureRef === feature.featureRef);
  }

  const openFeatureTitleClick = (e: any, feature: FeatureAssociation) => {
    e.preventDefault();
    e.stopPropagation();
    onFeatureTitleClick && onFeatureTitleClick(feature.featureRef);
  }

  const openSpecParamTitleClick = (e: any, feature: FeatureAssociation, specParamCode: string) => {
    e.preventDefault();
    e.stopPropagation();
    onSpecParamTitleClick && onSpecParamTitleClick(feature.featureRef, specParamCode);
  }

  const onlyOneCoreAvailable = (featureCode: String, featureServiceDefault: String) => {
    if (featureServiceDefault === 'included') {
      const isOnlyCoreAvailable = specificationParameterList.filter(sp => sp.featureRef === featureCode && sp.serviceDefault === 'included');
      if (isOnlyCoreAvailable.length === 1) { return true; }
    }
    return false;
  }

  const atleastOneSpecAvailable = (featureCode: String) => {
    const atleastOneOptionalAvailable = specificationParameterList.filter(sp => sp.featureRef === featureCode);
    if (atleastOneOptionalAvailable.length <= 1) { return true; }
    return false;
  }

  const disableSpecificationDeleteButton = (specificationParamServiceDefault: String, featureAccociation: FeatureAssociation) => {
    return specificationParamServiceDefault === 'included' ? atleastOneSpecAvailable(featureAccociation.featureRef) || onlyOneCoreAvailable(featureAccociation.featureRef, featureAccociation.serviceDefault) : atleastOneSpecAvailable(featureAccociation.featureRef)
  }

  const showFeaturePreviewOrUpgradeDialog = (event: any, featureAccociation: FeatureAssociation) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedFeatureForUpgrade && setSelectedFeatureForUpgrade(featureAccociation);
  }

  const confirmFeatureRemove = (event: any, feature: FeatureAssociation) => {
    event.preventDefault();
    event.stopPropagation();
    confirmDialog({
      message: `Are you sure you want to remove feature ${feature.definition.name} from this solution?`,
      header: 'Remove feature?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => removeFeature && removeFeature(feature.featureRef)
    });
  }

  const confirmSpecificationParameterRemove = (event: any, specificationParamRef: string) => {
    event.preventDefault();
    event.stopPropagation();
    confirmPopup({
      target: event.currentTarget,
      message: 'Are you sure you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => removeSpecificationParameter && removeSpecificationParameter(specificationParamRef),
      reject: () => null
    });
  }

  const constructFeatureHeaderForAccordion = (featureAssociation: FeatureAssociation, index: number) => {
    return (
      <div className={styles.featureView}>
        <span className={styles.featureIconName}>
          <FeatureIcon />
          <span className={styles.featureName} data-testid={`featureName-${index}`} data-feature-name = "true">{featureAssociation.definition.name}</span>
          <span className={styles.moreInfo} onClick={(e) => openFeatureTitleClick(e, featureAssociation)} data-testid={`moreInfo${index + 1}`}>
                         More info
          </span>
          {
            solution?.productType === SolutionType.INTEGRATED_SOLUTION && isNewFeatureVersionAvailable && isNewFeatureVersionAvailable(featureAssociation.definition) && (
              <>
                <div className={styles.featureVersionAvailable}>
                  New version available
                </div>
                {
                  <div className={styles.previewLink} onClick={(e) => showFeaturePreviewOrUpgradeDialog(e, featureAssociation)} data-testid={`upgradeLink-${index}`}>
                    {
                      isEditable ?
                        "Preview to upgrade"
                        :
                        "Preview"
                    }

                  </div>
                }
              </>
            )
          }
        </span>
        <span className={styles.featureActionButtons}>
          <span className={featureAssociation.serviceDefault === 'included' ? `${styles.featureTag} ${styles.coreFeatureTag}` : ` ${styles.featureTag} ${styles.optionalFeatureTag}`} data-testid={`featureServiceType-${index}`}>
            {ServiceDefaultLabel[featureAssociation.serviceDefault]}
          </span>
          {
            isEditable && removeFeature &&
            <Button text icon="pi pi-trash" className={styles.removeFeatureBtn} onClick={(event) => confirmFeatureRemove(event, featureAssociation)} data-testid={`deleteFeature-${index}`} />
          }
          <div>
            <Tooltip target={`.featureRemoveTooltip-${featureAssociation.featureRef}`} className="general-tooltip" autoHide={true} position="left" disabled={selectedFeatureForEdit !== featureAssociation.featureRef}>
              There are unsaved changes. Please, cancel or save before editing this feature.
            </Tooltip>
          </div>
        </span>
      </div>
    )
  }

  return (
    <div className={`${styles.featureAccordion} featureAccordion`} data-testid="FeatureAccordion">
      <Accordion multiple activeIndex={activeIndex}  onTabChange={(e: any) => setActiveIndex(e.index)} 
>
        {
          featureList.map((featureAssociation, featureIndex) => {
            return (
              <AccordionTab key={featureIndex} header={constructFeatureHeaderForAccordion(featureAssociation, featureIndex)}>
                {
                  getAssociatedSpecParamsOfFeature(featureAssociation).map((specificationParameteAssociation, specIndex) => {
                    return (
                      <div key={specIndex}>
                        <hr />
                        <div className={styles.specificationParameterView} >
                          <span className={styles.specificationParameterIconName} onClick={(e) => openSpecParamTitleClick(e, featureAssociation, specificationParameteAssociation.specificationParamRef)}>
                            <SpecificationParameterIcon />
                            <span>{specificationParameteAssociation.definition.name}</span>
                          </span>
                          <span className={styles.spOptionalityTagActionButtons}>
                            {SPDefaultLabel[specificationParameteAssociation.serviceDefault] !== "" &&
                              <span className={specificationParameteAssociation.serviceDefault === 'included' ? `${styles.spTag} ${styles.coreSPTag}` : `${styles.spTag} ${styles.optionalSPTag}`} data-testid={`spServiceType-${featureIndex}-${specIndex}`}>
                                {SPDefaultLabel[specificationParameteAssociation.serviceDefault]}
                              </span>
                            }
                            {isEditable &&
                              <Button icon="pi pi-trash" disabled={disableSpecificationDeleteButton(specificationParameteAssociation.serviceDefault, featureAssociation)}
                                onClick={(event) => confirmSpecificationParameterRemove(event, specificationParameteAssociation.specificationParamRef)}
                                text className={styles.removeSPBtn} data-testid={`spDeleteIcon-${featureIndex}-${specIndex}`}
                              />
                            }
                          </span>
                        </div>
                      </div>
                    )
                  })
                }

              </AccordionTab>
            )
          })}
      </Accordion>
      {
      }
    </div>
  )
};

export default FeatureAccordion;

import React, { FC, useContext, useEffect, useState } from 'react';
import styles from './EditDimensionView.module.scss';
import { Dimension, Scope } from "../../data/model/DataModels";
import { PickList } from "../../components/PickList/PickList";
import { PickListRegion } from "../../components/PickListRegion/PickListRegion";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useGetDimensionTypesQuery, useGetListTypeDimensionDataQuery, useGetTreeTypeDimensionDataQuery } from '../../data/api/RefDataApi';
import { dimensionIncludeValues } from '../../components/utils/dimensionData';
import SpinnerComponent from '../../components/Spinner/SpinnerComponent';
import { ToasterContext } from '../AppLayoutView/AppLayoutView';
import { asyncBuildTreeFromSelectedValuesAndAddIcons, buildTreeFromSelectedValuesAndAddIcons } from '../../components/ScopeComponents/ScopeHelper';
import PickListTree from '../../components/PickListTree/PickListTree';
import { cloneDeep } from 'lodash';
import RegionSelection from '../RegionSelection/RegionSelection';
import { TooltipOptions } from 'primereact/tooltip/tooltipoptions';

export interface EditDimensionProps {
  scope: Scope,
  mainTree?: any[];
  header?: string,
  searchKey: string,
  dimension: Dimension,
  onHide?: Function,
  onEditDone?: Function
}

export const EditDimensionView: FC<EditDimensionProps> =
  ({
    scope,
    mainTree = [],
    header = "",
    dimension,
    onHide,
    onEditDone
  }) => {

    const { data: allDimensionsData = [], error: errorAllDimensionsData, isLoading: isLoadingAllDimensionsData } = useGetDimensionTypesQuery();

    const getDimensionType = (dimensionCode: string) => {
      return allDimensionsData.find(dimension => dimension.code == dimensionCode)?.type || "list";
    }

    const { data: allListDimensionValues = [], error: errorAllListDimensionValues, isLoading: isLoadingAllListDimensionValues } = useGetListTypeDimensionDataQuery(dimension.type, { skip: getDimensionType(dimension.type) != "list" });
    const { data: allTreeDimensionValues = [], isLoading: isLoadingAllTreeDimensionValues } = useGetTreeTypeDimensionDataQuery(dimension.type, { skip: getDimensionType(dimension.type) != "tree" });

    const [selectedRegion, setSelectedRegion] = useState("APA");
    const [includedOptions, setIncludedOptions] = useState(dimension.value);
    const [sourceTree, setSourceTree] = useState([] as any[]);
    const [targetTree, setTargetTree] = useState([] as any[]);
    const [showSpinner, setShowSpinner] = useState(false);

    useEffect(() => {
      if (getDimensionType(dimension.type) === "multitree") {
        setSourceTree(buildTreeFromSelectedValuesAndAddIcons(mainTree.find(region => region.code == selectedRegion).children, dimension.value.find((region: any) => region.code === selectedRegion).values));
        setTargetTree(buildTreeFromSelectedValuesAndAddIcons(mainTree.find(region => region.code == selectedRegion).children, dimension.value.find((region: any) => region.code === selectedRegion).values, "exclusion"));
      }
    }, []);




    const toaster = useContext(ToasterContext);

    if (errorAllDimensionsData) {
      toaster.showToast('error', 'Failed to fetch dimensions definitions');
    }

    if (errorAllListDimensionValues) {
      toaster.showToast('error', 'Failed to fetch list type dimension values');
    }

    const sourceItemTemplate = (item: any) => {
      return (
        <>
          {
            <div className={styles.editDimensionItems}>
              <i className={'pi pi-check-circle ' + styles.checkIcon} />
              <span>
                {item.name}
              </span>
            </div>
          }
        </>
      )
    };

    const targetItemTemplate = (item: any) => {
      return (
        <>
          {
            <div className={styles.editDimensionItems}>
              <i className={'pi pi-ban ' + styles.banIcon} />
              <span>
                {item.name}
              </span>
            </div>
          }
        </>
      )
    };

    const onPicklistChange = (source: any, target: any) => {
      if (!target || (target && target.length == 0)) {
        setIncludedOptions([dimensionIncludeValues.ALL_INCLUDED]);
      } else {
        setIncludedOptions(source.map((option: any) => option.code));
      }
    };

    const onPicklistRegionChange = (source: any[], target: any[]) => {
      const subTree = mainTree.find(region => region.code == selectedRegion).children;

      setShowSpinner(true);

      setTimeout(() =>
        asyncBuildTreeFromSelectedValuesAndAddIcons(subTree, source, "inclusion").then(_sourceTree => {
          asyncBuildTreeFromSelectedValuesAndAddIcons(subTree, source, "exclusion").then((_targetTree: any[]) => {
            setSourceTree(_sourceTree);
            setTargetTree(_targetTree);
            let includedOptionsCopy = cloneDeep(includedOptions);
            let optionToUpdate = includedOptionsCopy.find((option: any) => option.code === selectedRegion);
            const optionUpdateDefinition = getOptions().find((option: any) => option.code === selectedRegion);
            if (source.length === optionUpdateDefinition?.numberOfLeaves) {
              optionToUpdate.values = [dimensionIncludeValues.ALL_INCLUDED];
            } else {
              optionToUpdate.values = source;
            }
            setIncludedOptions(includedOptionsCopy);
            setShowSpinner(false);
          })
        }),
        0
      );

    }

    const onDialogDone = () => {
      onEditDone && onEditDone(scope, includedOptions);
      onHide && onHide();
    };

    const onCancel = () => {
      onHide && onHide();
    };

    const getSourceData = () => {
      if (dimension?.value[0] === dimensionIncludeValues.ALL_INCLUDED) {
        return allListDimensionValues;
      } else {
        return allListDimensionValues.filter(val => dimension.value.includes(val.code));
      }
    }
    const getTargetData = () => {
      if (dimension?.value[0] === dimensionIncludeValues.ALL_INCLUDED) {
        return [];
      } else {
        return allListDimensionValues.filter(val => !dimension.value.includes(val.code));
      }
    }

    const isDoneDisabled = () => {
      if (getDimensionType(dimension.type) === "multitree") {
        let allRegionsExcluded = true;
        includedOptions.forEach((option: any) => {
          if (option.values.length) {
            allRegionsExcluded = false;
          }
        });
        return allRegionsExcluded;
      }
      return includedOptions.length === 0;
    }

    const renderDialogFooter = () => {

      let tooltipOptions = { showOnDisabled: true, disabled: includedOptions.length > 0, position: "top", className: "general-tooltip" } as TooltipOptions;

      return (
        <div>
          <Button label="Cancel" onClick={onCancel} className="p-button-outlined" />
          <Button label="Done" disabled={isDoneDisabled()} tooltip={"At least one item must be included"} tooltipOptions={tooltipOptions} onClick={(e) => onDialogDone()} className={styles.picklistDoneButton} />
        </div>
      );
    };

    const getSubtree = (regionCode: string) => {
      return mainTree.find(region => region.code == regionCode).children;
    }

    const getOptions = (): { name: string, code: string, numberOfLeaves: number }[] => {
      if (!mainTree) {
        return [] as { name: string, code: string, numberOfLeaves: number }[];
      }
      return mainTree.map(region => {
        let numberOfLeaves = 0;
        region.children.forEach((area: any) =>
          area.children.forEach((country: any) => numberOfLeaves = numberOfLeaves + country.children.length)
        )
        return { name: region.name, code: region.code, numberOfLeaves };
      });
    }

    const selectRegion = (regionCode: string) => {
      const subTree = getSubtree(regionCode);
      setShowSpinner(true);
      // to free the thread for further processing we use setTimeout
      setTimeout(() =>
        asyncBuildTreeFromSelectedValuesAndAddIcons(subTree, includedOptions.find((region: any) => region.code === regionCode).values, "inclusion").then(_sourceTree => {
          asyncBuildTreeFromSelectedValuesAndAddIcons(subTree, includedOptions.find((region: any) => region.code === regionCode).values, "exclusion").then((_targetTree: any[]) => {
            setSourceTree(_sourceTree);
            setTargetTree(_targetTree);
            setShowSpinner(false);
          })
        }),
        0
      )
      setSelectedRegion(regionCode);
    }

    const onPicklistTreeChange = (source: any, target: any) => {
      if (!target || (target && target.length == 0)) {
        setIncludedOptions([dimensionIncludeValues.ALL_INCLUDED]);
      } else {
        setIncludedOptions(source);
      }
    };

    const choosePicklist = (dimensionType: string) => {
      switch (dimensionType) {
        case "tree":
          return (
            <PickListTree
              mainTree={allTreeDimensionValues}
              sourceValues={includedOptions || []}
              searchKey="name"
              sourceHeader="Included" targetHeader="Excluded"
              onChange={onPicklistTreeChange}
            />
          )
        case "multitree":
          return (
            <>
              <div className={styles.regionList}>
                <RegionSelection regionOptions={getOptions()} selectedValues={includedOptions} selectedRegionCode={selectedRegion} onRegionSelection={(regionCode: string) => selectRegion(regionCode)} />
              </div>

              <PickListRegion
                subMainTree={getSubtree(selectedRegion)}
                sourceValues={includedOptions.find((region: any) => region.code === selectedRegion).values || []}
                sourceTree={sourceTree}
                targetTree={targetTree}
                searchKey="name"
                sourceHeader="Included" targetHeader="Excluded"
                onChange={onPicklistRegionChange}
              />
            </>
          )
        default:
          return (
            <PickList sourceItemTemplate={sourceItemTemplate} targetItemTemplate={targetItemTemplate}
              source={getSourceData()}
              target={getTargetData()} sortingKey="name"
              sourceHeader="Included" targetHeader="Excluded"
              onChange={onPicklistChange} />
          )
      }

    }

    return (

      <div className={styles.EditDimension} data-testid="EditDimension">
        {
          (allListDimensionValues.length > 0 || allTreeDimensionValues.length > 0 || sourceTree.length > 0 || targetTree.length > 0) &&
          <Dialog visible={true} onHide={onCancel} header={`${header}`}
            footer={renderDialogFooter}
          >
            {(isLoadingAllListDimensionValues || isLoadingAllDimensionsData || showSpinner) && <SpinnerComponent />}

            {
              choosePicklist(getDimensionType(dimension.type))
            }

          </Dialog>
        }

      </div>
    );
  };

export default EditDimensionView;
